<template>
    <modal @close="closeFromModal">
        <div slot="header" class="w-100">
            <button type="button" @click="close" class="close text-right" data-dismiss="modal" aria-label="Close">
                <span style="color: grey" aria-hidden="true">&times;</span>
            </button>
            <div
                v-if="product.img !== null"
                class="product-image-modal"
                :style="`background-image: url('${product.img}'); height: 360px;`"></div>
        </div>
        <div slot="body" class="p-3" style="position: relative">
            <h5 class="product-title noshow-widget-font-title" :style="`color: ${bgColor} !important`">
                {{ product.name }}
            </h5>
            <p class="product-description" v-html="product.description.replace(/\n/g, '<br/>')"></p>
            <div class="product-infos-modal mt-4 pt-3">
                <div class="row mb-2">
                    <div class="col-5">Validité</div>
                    <div class="col-7">{{ product.validity }} jours</div>
                </div>
                <div class="row" v-if="product.terms_of_use">
                    <div class="col-5">Conditions d'utilisation</div>
                    <div class="col-7">
                        {{ product.terms_of_use }}
                    </div>
                </div>
            </div>
        </div>
        <div slot="footer" class="p-3">
            <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">
                <div></div>
                <div>
                    <div class="product-price d-inline-block mr-3 text-body">{{ $utils.getAmountPrice(product) }}€</div>
                    <button
                        :disabled="!productActive"
                        @click="addToCart"
                        :style="`background: ${bgColor}`"
                        class="main-button product-modal-btn-add_product">
                        Ajouter
                    </button>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
export default {
    props: {
        product: {
            type: Object,
            required: true,
        },
    },
    computed: {
        bgColor() {
            return this.$store.getters["restaurant/bgColor"];
        },
        storeProduct() {
            return this.$store.getters["cart/product"];
        },
        productActive() {
            return this.product.active && this.storeProduct === null;
        },
    },
    methods: {
        addToCart() {
            let options = [];
            this.$store.dispatch("cart/setProduct", {
                product: this.product,
            });
            this.close();
        },
        close() {
            this.$emit("close");
        },
        closeFromModal: function (e) {
            e.stopPropagation();
            if (e && e.target && !e.target.classList.contains("modal-wrapper")) {
                e.stopPropagation();
            } else {
                this.close();
            }
        },
    },
};
</script>
