<template>
    <div class="d-flex flex-column align-items-center justify-content-center">
        <h5>
            {{ $t("errors.common.maintenance") }}
        </h5>
    </div>
</template>

<script>
export default {
    name: "MaintenanceError",
};
</script>
