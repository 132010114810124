import axios from "axios";

export default {
    fetchRestaurantData({ commit }) {
        return axios
            .get(`${API_URL}/${this._vm.$api_key}/restaurant?include=gv_config`)
            .then((response) => {
                commit("setBgColor", response.data.bgcolor);
                commit("setStripeClientId", response.data.stripe_client_id);
                commit("setHasCardPaymentSystem", response.data.has_card_payment_system);
                commit("setConfig", response.data.gv_config);
                commit("setWidgetFontTitle", response.data.widget_font_title);
                commit("setDefaultTelCountry", response.data.default_tel_country);
                commit("setTimezone", response.data.timezone);
            })
            .catch((error) => {
                console.error(error);
            });
    },
    resetData({ commit }) {
        commit("setHasCardPaymentSystem", false);
        commit("setBgColor", null);
        commit("setConfig", null);
        commit("setWidgetFontTitle", "'Open Sans', sans-serif");
        commit("setDefaultTelCountry", "FR");
    },
};
