var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "product mb-3",
      class: { disable: !_vm.product.active },
      on: { click: _vm.showModal },
    },
    [
      _c("div", { staticClass: "row m-0" }, [
        _vm.product.img !== null
          ? _c("div", {
              staticClass: "col-12 product-image",
              style: `background-image: url('${_vm.product.img}')`,
            })
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "product-content" }, [
          _c("div", { staticClass: "col-12 pt-3 pb-2 product-infos" }, [
            _c(
              "h6",
              {
                staticClass: "product-title noshow-widget-font-title",
                style: `color: ${_vm.bgColor}`,
              },
              [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.product.name) +
                    "\n                "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "product-description",
                style:
                  "overflow:hidden;" +
                  (_vm.product.img !== null ? "max-height:5.2em;" : ""),
              },
              [
                _vm._v(
                  "\n                    " +
                    _vm._s(
                      _vm.getSubDescription(
                        _vm.product.description,
                        _vm.product.img !== null ? 60 : 150
                      )
                    ) +
                    "\n                "
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "col-12 pt-3 pb-2 product-footer d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center",
            },
            [
              _c("span", { staticClass: "product-price" }, [
                _vm._v(
                  _vm._s(
                    _vm.product.type === "amount"
                      ? _vm.$utils.getAmountPrice(_vm.product)
                      : _vm.$utils.formatPrice(_vm.product.price)
                  ) + "€"
                ),
              ]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass:
                    "main-button main-button-sm product-modal-btn-add_product",
                  style: `background: ${_vm.bgColor}`,
                  attrs: { disabled: !_vm.productActive },
                },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.product.active ? "Ajouter" : "Indisponible") +
                      "\n                "
                  ),
                ]
              ),
            ]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }