var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("modal", { on: { close: _vm.closeFromModal } }, [
    _c(
      "div",
      { staticClass: "w-100", attrs: { slot: "header" }, slot: "header" },
      [
        _c("div", { staticClass: "modal-payment-header border-bottom p-3" }, [
          _c(
            "button",
            {
              staticClass: "close text-right",
              attrs: {
                type: "button",
                "data-dismiss": "modal",
                "aria-label": "Close",
              },
              on: { click: _vm.close },
            },
            [
              _c(
                "span",
                {
                  staticStyle: { color: "grey" },
                  attrs: { "aria-hidden": "true" },
                },
                [_vm._v("×")]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "h5",
            {
              staticClass: "mt-2 noshow-widget-font-title",
              style: `color: ${_vm.bgColor}`,
            },
            [_vm._v("Paiement")]
          ),
          _vm._v(" "),
          _c("small", {
            staticClass: "text-danger d-block noshow-giftvouchers-form-error",
            attrs: { id: "noshow-giftvouchers-form-error-mainerror" },
          }),
          _vm._v(" "),
          _c("small", { staticClass: "d-block" }, [
            _vm._v(
              "Pour toute information relative au délai de rétractation, merci de vous référer à nos\n                "
            ),
            _c(
              "a",
              {
                attrs: {
                  href:
                    _vm.customCgv ||
                    `${_vm.base_url}/gift_vouchers/widget/public/${this.$api_key}/cgv`,
                  target: "_blank",
                },
              },
              [_vm._v("conditions générales de vente")]
            ),
            _vm._v("."),
          ]),
        ]),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { attrs: { slot: "body" }, slot: "body" },
      [
        _vm.loading
          ? _c("loader-component", { staticClass: "w-100 text-center" })
          : _vm._e(),
        _vm._v(" "),
        _c("show-errors", {
          staticClass: "d-block",
          attrs: { errors: _vm.errors, errorKey: "main_error" },
        }),
        _vm._v(" "),
        _c(
          "form",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.step === 1,
                expression: "step === 1",
              },
            ],
            staticClass: "modal-content",
            on: { submit: _vm.postForm },
          },
          [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.loading && !_vm.canDisplayCountdown,
                    expression: "!loading && !canDisplayCountdown",
                  },
                ],
                staticClass: "border-bottom p-3",
              },
              [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-3" }, [
                    _c("h6", { staticClass: "noshow-widget-font-title" }, [
                      _vm._v("Expédition"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-sm-9" },
                    [
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-12 radio" },
                          [
                            _c(
                              "div",
                              [
                                _c("label", { staticClass: "mb-1" }, [
                                  _vm._v("Comment recevoir mon bon cadeau ? *"),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "label",
                                  {
                                    staticClass: "container pr-0",
                                    staticStyle: { width: "inherit" },
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.expeditionType,
                                          expression: "expeditionType",
                                        },
                                      ],
                                      staticClass: "mt-2",
                                      attrs: { value: "email", type: "radio" },
                                      domProps: {
                                        checked: _vm._q(
                                          _vm.expeditionType,
                                          "email"
                                        ),
                                      },
                                      on: {
                                        change: function ($event) {
                                          _vm.expeditionType = "email"
                                        },
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("span", {
                                      staticClass: "checkmark",
                                      style:
                                        _vm.expeditionType == "email"
                                          ? `background-color: ${_vm.bgColor};`
                                          : "",
                                    }),
                                    _vm._v(
                                      "\n                                        Par mail (format PDF)\n                                    "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("feather", {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip",
                                      value: {
                                        content:
                                          "Le mail sera envoyé dès la confirmation de votre paiement",
                                        triggers: ["touch", "hover"],
                                      },
                                      expression:
                                        "{\n                                            content: 'Le mail sera envoyé dès la confirmation de votre paiement',\n                                            triggers: ['touch', 'hover'],\n                                        }",
                                    },
                                  ],
                                  staticClass: "text-warning mr-3",
                                  staticStyle: { width: "1.1em" },
                                  attrs: { type: "info" },
                                }),
                                _vm._v(" "),
                                _vm.isPostalAvailable
                                  ? _c(
                                      "label",
                                      {
                                        staticClass: "container",
                                        staticStyle: { width: "inherit" },
                                      },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.expeditionType,
                                              expression: "expeditionType",
                                            },
                                          ],
                                          staticClass: "mt-2",
                                          attrs: {
                                            value: "postal_mail",
                                            type: "radio",
                                          },
                                          domProps: {
                                            checked: _vm._q(
                                              _vm.expeditionType,
                                              "postal_mail"
                                            ),
                                          },
                                          on: {
                                            change: function ($event) {
                                              _vm.expeditionType = "postal_mail"
                                            },
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("span", {
                                          staticClass: "checkmark",
                                          style:
                                            _vm.expeditionType == "postal_mail"
                                              ? `background-color: ${_vm.bgColor};`
                                              : "",
                                        }),
                                        _vm._v(
                                          "\n                                        Par courrier\n                                    "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("show-errors", {
                              attrs: {
                                errors: _vm.errors,
                                errorKey: "expedition_type",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-12 radio" },
                          [
                            _c("div", [
                              _c("label", { staticClass: "mb-1" }, [
                                _vm._v("Destinataire *"),
                              ]),
                              _vm._v(" "),
                              _c(
                                "label",
                                {
                                  staticClass: "container",
                                  staticStyle: { width: "inherit" },
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.hasBeneficiary,
                                        expression: "hasBeneficiary",
                                      },
                                    ],
                                    staticClass: "mt-2 mr-1",
                                    attrs: { type: "radio" },
                                    domProps: {
                                      value: true,
                                      checked: _vm._q(_vm.hasBeneficiary, true),
                                    },
                                    on: {
                                      change: function ($event) {
                                        _vm.hasBeneficiary = true
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("span", {
                                    staticClass: "checkmark",
                                    style: _vm.hasBeneficiary
                                      ? `background-color: ${_vm.bgColor};`
                                      : "",
                                  }),
                                  _vm._v(
                                    "\n                                        Pour offrir\n                                    "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "label",
                                {
                                  staticClass: "container",
                                  staticStyle: { width: "inherit" },
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.hasBeneficiary,
                                        expression: "hasBeneficiary",
                                      },
                                    ],
                                    staticClass: "mt-2 mr-1",
                                    attrs: { type: "radio" },
                                    domProps: {
                                      value: false,
                                      checked: _vm._q(
                                        _vm.hasBeneficiary,
                                        false
                                      ),
                                    },
                                    on: {
                                      change: function ($event) {
                                        _vm.hasBeneficiary = false
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("span", {
                                    staticClass: "checkmark",
                                    style: !_vm.hasBeneficiary
                                      ? `background-color: ${_vm.bgColor};`
                                      : "",
                                  }),
                                  _vm._v(
                                    "\n                                        Pour moi-même\n                                    "
                                  ),
                                ]
                              ),
                            ]),
                            _vm._v(" "),
                            _c("show-errors", {
                              attrs: {
                                errors: _vm.errors,
                                errorKey: "has_beneficiary",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _vm.hasBeneficiary
                        ? [
                            _c("div", { staticClass: "row" }, [
                              _c(
                                "div",
                                { staticClass: "col-sm-6" },
                                [
                                  _c("label", [
                                    _vm._v(
                                      "Nom du bénéficiaire *\n                                        "
                                    ),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.beneficiary
                                              .beneficiary_lastname,
                                          expression:
                                            "beneficiary.beneficiary_lastname",
                                        },
                                      ],
                                      staticClass: "form-control mt-2",
                                      attrs: { type: "text" },
                                      domProps: {
                                        value:
                                          _vm.beneficiary.beneficiary_lastname,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            _vm.beneficiary,
                                            "beneficiary_lastname",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                  ]),
                                  _vm._v(" "),
                                  _c("show-errors", {
                                    attrs: {
                                      errors: _vm.errors,
                                      errorKey: "beneficiary_lastname",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "col-sm-6" },
                                [
                                  _c("label", [
                                    _vm._v(
                                      "Prénom du bénéficiaire *\n                                        "
                                    ),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.beneficiary
                                              .beneficiary_firstname,
                                          expression:
                                            "beneficiary.beneficiary_firstname",
                                        },
                                      ],
                                      staticClass: "form-control mt-2",
                                      attrs: { type: "text" },
                                      domProps: {
                                        value:
                                          _vm.beneficiary.beneficiary_firstname,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            _vm.beneficiary,
                                            "beneficiary_firstname",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                  ]),
                                  _vm._v(" "),
                                  _c("show-errors", {
                                    attrs: {
                                      errors: _vm.errors,
                                      errorKey: "beneficiary_firstname",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _vm._v(" "),
                            _vm.expeditionType === "postal_mail"
                              ? _c("div", { staticClass: "row mt-3" }, [
                                  _c(
                                    "div",
                                    { staticClass: "col-12" },
                                    [
                                      _c(
                                        "label",
                                        { staticClass: "container" },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.deliver_to_beneficiary,
                                                expression:
                                                  "deliver_to_beneficiary",
                                              },
                                            ],
                                            attrs: { type: "checkbox" },
                                            domProps: {
                                              checked: Array.isArray(
                                                _vm.deliver_to_beneficiary
                                              )
                                                ? _vm._i(
                                                    _vm.deliver_to_beneficiary,
                                                    null
                                                  ) > -1
                                                : _vm.deliver_to_beneficiary,
                                            },
                                            on: {
                                              change: function ($event) {
                                                var $$a =
                                                    _vm.deliver_to_beneficiary,
                                                  $$el = $event.target,
                                                  $$c = $$el.checked
                                                    ? true
                                                    : false
                                                if (Array.isArray($$a)) {
                                                  var $$v = null,
                                                    $$i = _vm._i($$a, $$v)
                                                  if ($$el.checked) {
                                                    $$i < 0 &&
                                                      (_vm.deliver_to_beneficiary =
                                                        $$a.concat([$$v]))
                                                  } else {
                                                    $$i > -1 &&
                                                      (_vm.deliver_to_beneficiary =
                                                        $$a
                                                          .slice(0, $$i)
                                                          .concat(
                                                            $$a.slice($$i + 1)
                                                          ))
                                                  }
                                                } else {
                                                  _vm.deliver_to_beneficiary =
                                                    $$c
                                                }
                                              },
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("span", {
                                            staticClass: "checkmark",
                                            style: _vm.deliver_to_beneficiary
                                              ? `background-color: ${_vm.bgColor} !important;`
                                              : "",
                                          }),
                                          _vm._v(
                                            "Envoyer au bénéficiare\n                                    "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("ShowErrors", {
                                        attrs: {
                                          errors: _vm.errors,
                                          errorKey: "deliver_to_beneficiary",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.expeditionType === "email"
                              ? [
                                  _c("div", { staticClass: "row" }, [
                                    _c(
                                      "div",
                                      { staticClass: "col-12" },
                                      [
                                        _c("label", [
                                          _vm._v(
                                            "Mail de réception du bon cadeau *\n                                            "
                                          ),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.beneficiary
                                                    .beneficiary_email,
                                                expression:
                                                  "beneficiary.beneficiary_email",
                                              },
                                            ],
                                            staticClass: "form-control mt-2",
                                            attrs: { type: "email" },
                                            domProps: {
                                              value:
                                                _vm.beneficiary
                                                  .beneficiary_email,
                                            },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.$set(
                                                  _vm.beneficiary,
                                                  "beneficiary_email",
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          }),
                                        ]),
                                        _vm._v(" "),
                                        _c("show-errors", {
                                          attrs: {
                                            errors: _vm.errors,
                                            errorKey: "beneficiary_email",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "row" }, [
                                    _c(
                                      "div",
                                      { staticClass: "col-12" },
                                      [
                                        _c("label", [
                                          _vm._v(
                                            "Message personnalisé à ajouter au mail (optionnel)"
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("textarea", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.beneficiary
                                                  .beneficiary_message,
                                              expression:
                                                "beneficiary.beneficiary_message",
                                            },
                                          ],
                                          staticClass: "form-control",
                                          attrs: { rows: "5" },
                                          domProps: {
                                            value:
                                              _vm.beneficiary
                                                .beneficiary_message,
                                          },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.$set(
                                                _vm.beneficiary,
                                                "beneficiary_message",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("show-errors", {
                                          attrs: {
                                            errors: _vm.errors,
                                            errorKey: "beneficiary_message",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                ]
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.expeditionType === "postal_mail" &&
                            _vm.deliver_to_beneficiary
                              ? [
                                  _c("div", { staticClass: "row mt-2" }, [
                                    _c("div", { staticClass: "col-12" }, [
                                      _c(
                                        "label",
                                        [
                                          _vm._v(
                                            "\n                                            Adresse d'expédition *\n                                            "
                                          ),
                                          _c("postal-address-autocomplete", {
                                            attrs: {
                                              "is-international-available":
                                                _vm.isInternationalAvailable,
                                            },
                                            model: {
                                              value: _vm.beneficiaryAddress,
                                              callback: function ($$v) {
                                                _vm.beneficiaryAddress = $$v
                                              },
                                              expression: "beneficiaryAddress",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ]),
                                ]
                              : _vm._e(),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.loading && !_vm.canDisplayCountdown,
                    expression: "!loading && !canDisplayCountdown",
                  },
                ],
                staticClass: "p-3 border-bottom",
              },
              [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-3" }, [
                    _c("h6", { staticClass: "noshow-widget-font-title" }, [
                      _vm._v("Mes coordonnées"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-sm-9" },
                    [
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-12 radio" },
                          [
                            _c("div", [
                              _c("label", { staticClass: "mb-1" }, [
                                _vm._v("Civilité *"),
                              ]),
                              _vm._v(" "),
                              _c(
                                "label",
                                {
                                  staticClass: "container",
                                  staticStyle: { width: "inherit" },
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.client.civility,
                                        expression: "client.civility",
                                      },
                                    ],
                                    staticClass: "mt-2 mr-1",
                                    attrs: { value: "monsieur", type: "radio" },
                                    domProps: {
                                      checked: _vm._q(
                                        _vm.client.civility,
                                        "monsieur"
                                      ),
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.$set(
                                          _vm.client,
                                          "civility",
                                          "monsieur"
                                        )
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("span", {
                                    staticClass: "checkmark",
                                    style:
                                      _vm.client.civility == "monsieur"
                                        ? `background-color: ${_vm.bgColor};`
                                        : "",
                                  }),
                                  _vm._v(
                                    "\n                                        Monsieur\n                                    "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "label",
                                {
                                  staticClass: "container",
                                  staticStyle: { width: "inherit" },
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.client.civility,
                                        expression: "client.civility",
                                      },
                                    ],
                                    staticClass: "mt-2 mr-1",
                                    attrs: { value: "madame", type: "radio" },
                                    domProps: {
                                      checked: _vm._q(
                                        _vm.client.civility,
                                        "madame"
                                      ),
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.$set(
                                          _vm.client,
                                          "civility",
                                          "madame"
                                        )
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("span", {
                                    staticClass: "checkmark",
                                    style:
                                      _vm.client.civility == "madame"
                                        ? `background-color: ${_vm.bgColor};`
                                        : "",
                                  }),
                                  _vm._v(
                                    "\n                                        Madame\n                                    "
                                  ),
                                ]
                              ),
                            ]),
                            _vm._v(" "),
                            _c("show-errors", {
                              attrs: {
                                errors: _vm.errors,
                                errorKey: "civility",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-sm-6" },
                          [
                            _c("label", [
                              _vm._v(
                                "Nom *\n                                    "
                              ),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.client.lastname,
                                    expression: "client.lastname",
                                  },
                                ],
                                staticClass: "form-control mt-2",
                                attrs: { required: "", type: "text" },
                                domProps: { value: _vm.client.lastname },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.client,
                                      "lastname",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                            _vm._v(" "),
                            _c("show-errors", {
                              attrs: {
                                errors: _vm.errors,
                                errorKey: "lastname",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-sm-6" },
                          [
                            _c("label", [
                              _vm._v(
                                "Prénom *\n                                    "
                              ),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.client.firstname,
                                    expression: "client.firstname",
                                  },
                                ],
                                staticClass: "form-control mt-2",
                                attrs: { required: "", type: "text" },
                                domProps: { value: _vm.client.firstname },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.client,
                                      "firstname",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                            _vm._v(" "),
                            _c("show-errors", {
                              attrs: {
                                errors: _vm.errors,
                                errorKey: "firstname",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-sm-6" },
                          [
                            _c("label", [
                              _vm._v(
                                "Email *\n                                    "
                              ),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.client.email,
                                    expression: "client.email",
                                  },
                                ],
                                staticClass: "form-control mt-2",
                                attrs: { required: "", type: "email" },
                                domProps: { value: _vm.client.email },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.client,
                                      "email",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                            _vm._v(" "),
                            _c("show-errors", {
                              attrs: { errors: _vm.errors, errorKey: "email" },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-sm-6" },
                          [
                            _c("label", [_vm._v("Numéro de téléphone *")]),
                            _vm._v(" "),
                            _c("vue-tel-input", {
                              attrs: {
                                name: "telInput",
                                defaultCountry: _vm.defaultTelCountry,
                                enabledCountryCode: true,
                                inputClasses: "m-0 border-0",
                                mode: "international",
                                placeholder: "-- -- -- -- --",
                                required: "required",
                              },
                              on: { validate: _vm.setPhoneAndCountry },
                              model: {
                                value: _vm.tel_raw,
                                callback: function ($$v) {
                                  _vm.tel_raw = $$v
                                },
                                expression: "tel_raw",
                              },
                            }),
                            _vm._v(" "),
                            _c("show-errors", {
                              attrs: { errors: _vm.errors, errorKey: "tel" },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      (!_vm.hasBeneficiary || !_vm.deliver_to_beneficiary) &&
                      _vm.expeditionType === "postal_mail"
                        ? [
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-12" }, [
                                _c(
                                  "label",
                                  [
                                    _vm._v(
                                      "\n                                        Adresse d'expédition *\n                                        "
                                    ),
                                    _c("postal-address-autocomplete", {
                                      attrs: {
                                        "is-international-available":
                                          _vm.isInternationalAvailable,
                                      },
                                      model: {
                                        value: _vm.clientAddress,
                                        callback: function ($$v) {
                                          _vm.clientAddress = $$v
                                        },
                                        expression: "clientAddress",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ]),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.loading,
                    expression: "!loading",
                  },
                ],
                staticClass: "p-3 border-bottom",
              },
              [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-3" }, [
                    _c("h6", { staticClass: "noshow-widget-font-title" }, [
                      _vm._v("Paiement"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-9" }, [
                    _vm.isGroupPaymentAvailable && !_vm.token
                      ? _c("div", { staticClass: "row mt-3" }, [
                          _c(
                            "div",
                            { staticClass: "col-12" },
                            [
                              _c("label", { staticClass: "container" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.isGroupPayment,
                                      expression: "isGroupPayment",
                                    },
                                  ],
                                  attrs: { type: "checkbox" },
                                  domProps: {
                                    checked: Array.isArray(_vm.isGroupPayment)
                                      ? _vm._i(_vm.isGroupPayment, null) > -1
                                      : _vm.isGroupPayment,
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$a = _vm.isGroupPayment,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            (_vm.isGroupPayment = $$a.concat([
                                              $$v,
                                            ]))
                                        } else {
                                          $$i > -1 &&
                                            (_vm.isGroupPayment = $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1)))
                                        }
                                      } else {
                                        _vm.isGroupPayment = $$c
                                      }
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _c("span", {
                                  staticClass: "checkmark",
                                  style: _vm.isGroupPayment
                                    ? `background-color: ${_vm.bgColor} !important;`
                                    : "",
                                }),
                                _vm._v(
                                  "Paiement de groupe\n                                "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("ShowErrors", {
                                attrs: {
                                  errors: _vm.errors,
                                  errorKey: "isGroupPayment",
                                },
                              }),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isGroupPayment
                      ? _c(
                          "div",
                          { staticClass: "row" },
                          [
                            _c(
                              "div",
                              { staticClass: "col-12" },
                              [
                                _c("p", { staticClass: "mt-1 text-warning" }, [
                                  _vm._v(
                                    "Le montant minimum par personne est de 5€."
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("label", { staticClass: "d-block" }, [
                                  _vm._v(
                                    "\n                                    Votre part :\n                                    "
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model.number",
                                        value: _vm.ownerAmount,
                                        expression: "ownerAmount",
                                        modifiers: { number: true },
                                      },
                                    ],
                                    staticClass:
                                      "form-control mr-1 d-inline-block",
                                    staticStyle: { width: "20%" },
                                    attrs: {
                                      type: "number",
                                      min: "5",
                                      step: "0.01",
                                    },
                                    domProps: { value: _vm.ownerAmount },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.ownerAmount = _vm._n(
                                          $event.target.value
                                        )
                                      },
                                      blur: function ($event) {
                                        return _vm.$forceUpdate()
                                      },
                                    },
                                  }),
                                  _vm._v("€\n                                "),
                                ]),
                                _vm._v(" "),
                                _c("ShowErrors", {
                                  staticClass: "d-block",
                                  attrs: {
                                    errors: _vm.errors,
                                    errorKey: "ownerAmount",
                                  },
                                }),
                                _vm._v(" "),
                                _c("ShowErrors", {
                                  staticClass: "d-block",
                                  attrs: {
                                    errors: _vm.errors,
                                    errorKey: "groupPayments",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm._l(
                              _vm.groupPayments,
                              function (groupPayment, index) {
                                return _c(
                                  "div",
                                  { key: index, staticClass: "col-12" },
                                  [
                                    _c("label", [
                                      _vm._v(
                                        "\n                                    Email de la " +
                                          _vm._s(
                                            _vm.getPersonNumberLabel(index)
                                          ) +
                                          " personne et montant à payer *\n                                    "
                                      ),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: groupPayment.email,
                                            expression: "groupPayment.email",
                                          },
                                        ],
                                        staticClass:
                                          "form-control mt-2 d-inline-block",
                                        staticStyle: { width: "60%" },
                                        attrs: { type: "email" },
                                        domProps: { value: groupPayment.email },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              groupPayment,
                                              "email",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model.number",
                                            value: groupPayment.amount,
                                            expression: "groupPayment.amount",
                                            modifiers: { number: true },
                                          },
                                        ],
                                        staticClass:
                                          "form-control mt-2 ml-2 d-inline-block",
                                        staticStyle: { width: "20%" },
                                        attrs: {
                                          type: "number",
                                          min: "5",
                                          step: "0.01",
                                        },
                                        domProps: {
                                          value: groupPayment.amount,
                                        },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              groupPayment,
                                              "amount",
                                              _vm._n($event.target.value)
                                            )
                                          },
                                          blur: function ($event) {
                                            return _vm.$forceUpdate()
                                          },
                                        },
                                      }),
                                      _vm._v(
                                        "\n                                    €\n                                    "
                                      ),
                                      _c(
                                        "button",
                                        {
                                          directives: [
                                            {
                                              name: "tooltip",
                                              rawName: "v-tooltip",
                                              value: {
                                                content:
                                                  "Supprimer cette personne",
                                              },
                                              expression:
                                                "{ content: 'Supprimer cette personne' }",
                                            },
                                          ],
                                          staticClass:
                                            "btn btn-sm text-danger d-inline-block text-center",
                                          staticStyle: { width: "10%" },
                                          attrs: { type: "button" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.groupPayments.splice(
                                                index,
                                                1
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fas fa-times",
                                          }),
                                        ]
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("ShowErrors", {
                                      staticClass: "d-block",
                                      attrs: {
                                        errors: _vm.errors,
                                        errorKey: `groupPayments.${index}.email`,
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("ShowErrors", {
                                      staticClass: "d-block",
                                      attrs: {
                                        errors: _vm.errors,
                                        errorKey: `groupPayments.${index}.amount`,
                                      },
                                    }),
                                  ],
                                  1
                                )
                              }
                            ),
                          ],
                          2
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isGroupPayment
                      ? _c("div", { staticClass: "row mb-4" }, [
                          _c("div", { staticClass: "col-12" }, [
                            _c(
                              "button",
                              {
                                directives: [
                                  {
                                    name: "tooltip",
                                    rawName: "v-tooltip",
                                    value: { content: "Ajouter une personne" },
                                    expression:
                                      "{ content: 'Ajouter une personne' }",
                                  },
                                ],
                                staticClass: "main-button main-button-sm",
                                staticStyle: {
                                  width: "40px !important",
                                  "padding-left": "16px !important",
                                },
                                attrs: { type: "button" },
                                on: { click: _vm.addGroupPayment },
                              },
                              [_c("i", { staticClass: "fas fa-plus" })]
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.isGroupPayment,
                            expression: "!isGroupPayment",
                          },
                        ],
                        staticClass: "row single_payment",
                      },
                      [
                        _vm.canDisplayCountdown
                          ? _c(
                              "div",
                              {
                                staticClass: "d-block text-center text-warning",
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    "Il vous reste " +
                                      _vm._s(_vm.remainingMinsBeforeTimeout) +
                                      " minutes pour effectuer votre paiement."
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    "Passé ce délai, ce\n                                    dernier ne sera plus assuré."
                                  ),
                                ]),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.paymentIntentType ===
                        _vm.CARD_PAYMENT_SYSTEM_STRIPE.value
                          ? _c("StripePaymentForm", {
                              ref: "stripePaymentForm",
                              staticClass: "col-12",
                              attrs: {
                                paymentIntent: _vm.paymentIntent,
                                client: _vm.client,
                                stripe_client_id: _vm.stripe_client_id,
                              },
                              on: {
                                "set-loading": function ($event) {
                                  _vm.loading = $event
                                },
                                error: function ($event) {
                                  _vm.canDisplayCountdown = true
                                },
                                paid: function ($event) {
                                  return _vm.$emit("paid", $event)
                                },
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.paymentIntentType ===
                        _vm.CARD_PAYMENT_SYSTEM_PAYPLUG.value
                          ? _c("PayPlugPaymentForm", {
                              ref: "payplugPaymentForm",
                              staticClass: "col-12",
                              attrs: {
                                "can-display-message": true,
                                "sale-created-at": _vm.saleCreatedAt,
                                timezone: _vm.timezone,
                                paymentIntent: _vm.paymentIntent,
                                client: _vm.client,
                              },
                              on: {
                                "set-loading": function ($event) {
                                  _vm.loading = $event
                                },
                                error: function ($event) {
                                  _vm.canDisplayCountdown = true
                                },
                                paid: function ($event) {
                                  return _vm.$emit("paid", $event)
                                },
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "secure-payment text-muted mt-3" },
                      [
                        _c(
                          "a",
                          {
                            attrs: {
                              href: "https://stripe.com/fr",
                              target: "_blank",
                            },
                          },
                          [
                            _c(
                              "small",
                              [
                                _c("feather", {
                                  staticClass: "feather-18",
                                  attrs: { type: "lock" },
                                }),
                                _vm._v(" Paiement sécurisé"),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ]),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "p-3" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-3" }, [
                  _c("h6", { staticClass: "mb-0 noshow-widget-font-title" }, [
                    _vm._v("Total"),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-9" }, [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-7 text-right mb-2" }, [
                      _c("h6", { staticClass: "m-0 text-muted" }, [
                        _vm._v("Produit"),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-5 text-right mb-2" }, [
                      _c(
                        "h6",
                        {
                          staticClass: "mb-0 font-weight-bold",
                          style: `color: ${_vm.bgColor} !important;`,
                        },
                        [
                          _vm._v(
                            "\n                                    " +
                              _vm._s(_vm.$utils.formatPrice(_vm.productPrice)) +
                              "€\n                                "
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _vm.expeditionType === "postal_mail"
                      ? _c("div", { staticClass: "col-7 text-right mb-2" }, [
                          _c("h6", { staticClass: "m-0 text-muted" }, [
                            _vm._v("Expédition"),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.expeditionType === "postal_mail"
                      ? _c("div", { staticClass: "col-5 text-right mb-2" }, [
                          _c(
                            "h6",
                            {
                              staticClass: "mb-0 font-weight-bold",
                              style: `color: ${_vm.bgColor} !important;`,
                            },
                            [
                              _vm._v(
                                "\n                                    " +
                                  _vm._s(
                                    _vm.$utils.formatPrice(_vm.areaPrice)
                                  ) +
                                  "€\n                                "
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-7 text-right mb-2" }, [
                      _c("h6", { staticClass: "m-0 text-muted" }, [
                        _vm._v("Total"),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-5 text-right mb-2" }, [
                      _c(
                        "h6",
                        {
                          staticClass: "mb-0 font-weight-bold",
                          style: `color: ${_vm.bgColor} !important;`,
                        },
                        [
                          _vm._v(
                            "\n                                    " +
                              _vm._s(_vm.$utils.formatPrice(_vm.totalPrice)) +
                              "€\n                                "
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "footer-modal p-3" }, [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center",
                },
                [
                  _c(
                    "div",
                    [
                      _c("show-errors", {
                        staticClass: "d-block",
                        attrs: { errors: _vm.errors, errorKey: "cart" },
                      }),
                      _vm._v(" "),
                      _c("show-errors", {
                        staticClass: "d-block",
                        attrs: {
                          errors: _vm.errors,
                          errorKey: "general_terms",
                        },
                      }),
                      _vm._v(" "),
                      _c("label", { staticClass: "container" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.general_terms,
                              expression: "general_terms",
                            },
                          ],
                          attrs: {
                            disabled: _vm.loading,
                            required: "",
                            type: "checkbox",
                          },
                          domProps: {
                            checked: Array.isArray(_vm.general_terms)
                              ? _vm._i(_vm.general_terms, null) > -1
                              : _vm.general_terms,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.general_terms,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    (_vm.general_terms = $$a.concat([$$v]))
                                } else {
                                  $$i > -1 &&
                                    (_vm.general_terms = $$a
                                      .slice(0, $$i)
                                      .concat($$a.slice($$i + 1)))
                                }
                              } else {
                                _vm.general_terms = $$c
                              }
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("span", {
                          staticClass: "checkmark",
                          class: { disable: _vm.loading },
                          style: _vm.general_terms
                            ? `background-color: ${_vm.bgColor} !important;`
                            : "",
                        }),
                        _vm._v(" "),
                        _c("small", { staticClass: "text-muted" }, [
                          _vm._v(
                            "Je déclare accepter les\n                                "
                          ),
                          _c(
                            "a",
                            {
                              attrs: {
                                href:
                                  _vm.customCgv ||
                                  `${_vm.base_url}/gift_vouchers/widget/public/${this.$api_key}/cgv`,
                                target: "_blank",
                              },
                            },
                            [_vm._v("conditions générales de vente")]
                          ),
                          _vm._v(
                            "\n                                et de\n                                "
                          ),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: `${_vm.base_url}/gift_vouchers/widget/public/${this.$api_key}/policy`,
                                target: "_blank",
                              },
                            },
                            [
                              _vm._v(
                                "politique de confidentialité des données personnelles"
                              ),
                            ]
                          ),
                          _vm._v(
                            "\n                                et y adhère sans réserve. *"
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "payment-btn text-right" }, [
                    _c(
                      "button",
                      {
                        staticClass: "main-button mt-2 mt-md-0",
                        style: `background: ${_vm.bgColor}`,
                        attrs: {
                          disabled: _vm.hasError || _vm.loading,
                          type: "submit",
                        },
                      },
                      [
                        _vm._v(
                          "\n                            Payer\n                        "
                        ),
                      ]
                    ),
                  ]),
                ]
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _vm.step === 2
          ? _c("div", [
              _c("div", { staticClass: "w-100 p-3" }, [
                _c("div", { staticClass: "noshow-widget-font-title" }, [
                  _vm._v("Votre commande a bien été enregistrée !"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "mt-3" }, [
                  _c("p", [
                    _vm._v(
                      "\n                        Un email a été envoyé à chaque adresse email saisie afin de procéder au paiement."
                    ),
                    _c("br"),
                    _vm._v(
                      "\n                        Une fois les paiements effectués, vous recevrez un email afin de procéder à votre paiement."
                    ),
                    _c("br"),
                    _vm._v(" "),
                    _c("strong", [
                      _vm._v(
                        "Attention, vous disposez de 30 minutes pour procéder à tous les paiements."
                      ),
                    ]),
                    _c("br"),
                    _vm._v(
                      "\n                        Vous pouvez suivre les paiements depuis ce lien ou en cliquant sur le bouton ci dessous :"
                    ),
                    _c("br"),
                  ]),
                  _vm._v(" "),
                  _c("p"),
                  _vm._v(" "),
                  _c("div", { staticClass: "d-flex align-items-center mt-3" }, [
                    _c("input", {
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        readonly: "",
                        id: "noshow-gv-txt-copy-url",
                      },
                      domProps: { value: _vm.group_payment_url },
                    }),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.currentCopyTooltip,
                            expression: "currentCopyTooltip",
                          },
                        ],
                        staticClass: "btn",
                        attrs: { type: "button" },
                        on: { click: _vm.copyToken },
                      },
                      [
                        _c("i", {
                          staticClass: "far fa-copy",
                          staticStyle: { color: "#666666" },
                        }),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "w-100 text-center" }, [
                    _c(
                      "a",
                      {
                        attrs: {
                          target: "_blank",
                          href: _vm.group_payment_url,
                        },
                      },
                      [
                        _c(
                          "button",
                          {
                            staticClass: "btn main-button mt-4 btn-sm",
                            style: `background-color: ${_vm.bgColor} !important`,
                            attrs: { type: "button" },
                          },
                          [
                            _vm._v(
                              "\n                                Voir la liste des paiements\n                            "
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "w-100 p-3 border-top text-right" }, [
                _c(
                  "button",
                  {
                    staticClass: "main-button",
                    style: `background-color: ${_vm.bgColor} !important`,
                    attrs: { disabled: _vm.loading },
                    on: { click: _vm.close },
                  },
                  [_vm._v("Fermer")]
                ),
              ]),
            ])
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }