import * as Sentry from "@sentry/vue";
import Vue from "vue";

const initSentry = function (applicationKey) {
    const tracesSampleRate = APP_ENV === "production" ? 0.1 : 1.0;

    const dsn =
        THEME === "yservices"
            ? "https://d1096dfcafae419e8e540af0a31157b7@o59715.ingest.sentry.io/4504633553715200"
            : "https://8b753feb37a64758b90e316c832ff7b4@o59715.ingest.sentry.io/6228116";

    Sentry.init({
        Vue,
        dsn,
        tracesSampleRate,
        environment: APP_ENV,
        logErrors: true,
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.thirdPartyErrorFilterIntegration({
                filterKeys: [applicationKey],
                behaviour: "drop-error-if-exclusively-contains-third-party-frames",
            }),
        ],
    });
};

export { initSentry };
