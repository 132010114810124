<template>
    <div>
        <input v-if="value === null" type="text" class="form-control" v-model="search.input" :disabled="disabled" />
        <div v-else>
            <div type="text" class="form-control">{{ displayableAddress }}</div>
            <feather
                type="x"
                class="text-danger position-absolute feather-20"
                :class="{ pointer: disabled }"
                style="top: 42%; right: 18px"
                @click.stop="resetAddress" />
        </div>
        <div v-if="errors.search !== null" class="text-danger">{{ errors.search }}</div>
        <div v-else-if="search.hits.length > 0" class="dropdown-menu prename-field shadow show p-0" style="top: auto; left: auto">
            <loader-component v-if="loading.placeDetails" />
            <ul v-else class="list-group">
                <li v-for="hit in search.hits" :key="hit.description" class="list-group-item p-0">
                    <a class="d-block p-3" href="javascript:" @click="selectAddress(hit.place_id)">
                        <span>{{ hit.description }}</span>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import LoaderComponent from "../LoaderComponent.vue";
import axios from "axios";

export default {
    data() {
        return {
            sessionToken: null,
            loading: {
                placeDetails: false,
            },
            errors: {
                search: null,
            },
            search: {
                input: null,
                hits: [],
            },
        };
    },
    props: {
        /**
         * {
         *  locality: string,
         *  country: string,
         *  region: ?string,
         *  street_address: string,
         *  postal_code: ?string,
         * }
         */
        value: {
            type: Object,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        isInternationalAvailable: {
            type: Boolean,
            default: true,
        },
    },
    methods: {
        updateAddressFields(fields) {
            this.address = {
                ...this.address,
                ...fields,
            };
        },
        getDisplayableAddress(address) {
            let displayableAddress = `${address.street_address},`;

            if (address.postal_code !== null) {
                displayableAddress += ` ${address.postal_code}`;
            }

            displayableAddress += ` ${address.locality}`;

            if (address.region !== null) {
                displayableAddress += `, ${address.region}`;
            }

            return `${displayableAddress}, ${address.country}`;
        },
        searchAddress(search) {
            if (this.disabled || this.search.input !== search) {
                return;
            }

            if (this.sessionToken === null) {
                this.sessionToken = this.$utils.generateRandomString();
            }

            this.errors.search = null;

            axios
                .get(
                    `${API_URL}/${this.$api_key}/google/places/search/address?query=${this.search.input}&sessionToken=${
                        this.sessionToken
                    }&inFrance=${!this.isInternationalAvailable}`
                )
                .then((response) => {
                    if (response !== false) {
                        if (response.data.data.length > 0) {
                            this.search.hits = response.data.data;
                            return;
                        }

                        this.errors.search = this.$t("errors.common.address.noHits");
                    }

                    this.search.hits = [];
                })
                .catch((error) => {
                    this.errors.search = this.$utils.getErrorMsgFromErrorResponse(error);
                });
        },
        selectAddress(place_id) {
            if (this.disabled || this.loading.placeDetails) {
                return;
            }

            this.loading.placeDetails = true;

            axios
                .get(`${API_URL}/${this.$api_key}/google/places/details/${place_id}?sessionToken=${this.sessionToken}`)
                .then((response) => {
                    this.updateAddressFields({
                        locality: response.data.city,
                        country: response.data.country,
                        street_address: response.data.address,
                        postal_code: response.data.postalCode,
                    });

                    this.search.input = null;
                    this.search.hits = [];

                    this.sessionToken = null;
                })
                .catch((error) => {
                    this.errors.search = this.$utils.getErrorMsgFromErrorResponse(error);
                })
                .finally(() => (this.loading.placeDetails = false));
        },
        resetAddress() {
            if (this.disabled) {
                return;
            }

            this.address = null;
        },
    },
    computed: {
        address: {
            get() {
                return (
                    this.value ?? {
                        locality: null,
                        country: null,
                        region: null,
                        street_address: null,
                        postal_code: null,
                    }
                );
            },
            set(newVal) {
                this.$emit("input", newVal);
            },
        },
        displayableAddress() {
            if (this.value === null || this.value.street_address === null) {
                return null;
            }

            return this.getDisplayableAddress(this.address);
        },
    },
    watch: {
        "search.input": function (newVal) {
            if (newVal === null || newVal.length < 3) {
                this.search.hits = [];
                this.errors.search = null;
                this.sessionToken = null;
                return;
            }

            setTimeout(() => {
                this.searchAddress(newVal);
            }, 500);
        },
    },
    components: {
        LoaderComponent,
    },
};
</script>
