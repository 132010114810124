export default {
    errors: {
        common: {
            unknown: "Une erreur est survenue",
            empty_cart: "Votre panier est vide",
            cant_be_pay: "Cette commande ne peut plus être payée",
            already_paid: "Cette commande a déjà été payée",
            maintenance: "Le widget est actuellement indisponible. Merci de réessayer plus tard.",
            network: "Veuillez vérifier votre connexion internet",
            address: {
                noHits: "Cette adresse postale n'a pas été trouvée.",
            },
        },
        products: {
            custom_amount: {
                between: "Merci de saisir un montant compris entre {min}€ et {max}€",
                not_available: "Désolé, les montants personnalisés ne sont pas disponible pour ce bon cadeau",
            },
            amount: {
                invalid: "Désolé, ce montant n'est pas disponible",
            },
        },
        expedition: {
            area_not_available: "Désolé, cette zone de livraison n'est pas disponible",
        },
        group_payment: {
            sale_not_found: "Désolé, cette commande est introuvable",
            email_not_found: "Désolé, cette adresse email ne correspond à aucun paiement",
            captain_cant_pay: "Vous devez attendre tous les paiements avant de procéder au vôtre",
            already_paid: "Ce paiement a déjà été effectué",
            too_late: "Délai de paiement dépassé",
        },
    },
    confirm: {
        products: {
            delete: "Êtes vous sûr de vouloir supprimer ce produit ?",
        },
    },
    info: {
        paid: "Votre paiement a bien été pris en compte !",
    },
    loading: "Chargement ...",
};
