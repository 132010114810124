var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", {
      staticClass: "p-3 border mt-2",
      attrs: { id: "stripeCardForm" },
    }),
    _vm._v(" "),
    _vm.errorCard
      ? _c("small", { staticClass: "text-danger" }, [
          _vm._v(_vm._s(_vm.errorCard)),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }