export default {
    client: (state) => {
        return state;
    },
    id: (state) => {
        return state.id;
    },
    firstname: (state) => {
        return state.firstname;
    },
    lastname: (state) => {
        return state.lastname;
    },
    email: (state) => {
        return state.email;
    },
    tel: (state) => {
        return state.tel;
    },
    telCountry: (state) => {
        return state.telCountry;
    },
    company: (state) => {
        return state.company;
    },
    civility: (state) => {
        return state.civility;
    },
    address: (state) => {
        return state.address;
    },
    postal_code: (state) => {
        return state.postal_code;
    },
    city: (state) => {
        return state.city;
    },
    country: (state) => {
        return state.country;
    },
};
