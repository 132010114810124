<template>
    <modal @close="closeFromModal">
        <div slot="header" class="w-100">
            <button type="button" @click="close" class="close text-right" data-dismiss="modal" aria-label="Close">
                <span style="color: grey" aria-hidden="true">&times;</span>
            </button>
            <div
                v-if="product.img !== null"
                class="product-image-modal"
                :style="`background-image: url('${product.img}'); height: 360px;`"></div>
        </div>
        <div slot="body" class="p-3" style="position: relative">
            <h5 class="product-title noshow-widget-font-title" :style="`color: ${bgColor} !important`">
                {{ product.name }}
            </h5>
            <p class="product-description" v-html="product.description.replace(/\n/g, '<br/>')"></p>
            <div class="mt-3 pt-3 border-top">
                <div class="row">
                    <div class="col-sm-5">
                        <h6 class="noshow-widget-font-title">Quantité souhaitée</h6>
                    </div>
                    <div class="col-sm-7">
                        <div class="row">
                            <div class="col-12">
                                <button @click="quantity--" class="main-button second-btn">
                                    <i class="fas fa-minus"></i>
                                </button>
                                <input
                                    v-model="quantity"
                                    type="text"
                                    style="width: 40px; text-align: center"
                                    class="d-inline-block form-control pt-1 pl-1 pr-1" />
                                <button @click="quantity++" class="main-button second-btn">
                                    <i class="fas fa-plus"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                v-if="product.gv_products_options.length > 0 && quantityData > 0"
                class="modal-payment-body mt-3 pt-3 border-top">
                <div class="row">
                    <div class="col-sm-5">
                        <h6 class="noshow-widget-font-title">Choix des options</h6>
                    </div>
                    <div class="col-sm-7">
                        <div class="row">
                            <div class="col-12" v-for="(option, index) in product.gv_products_options" :key="option.id">
                                <label>
                                    <select
                                        class="custom-select d-inline-block mr-2 product_option_quantity"
                                        style="width: 60px"
                                        v-model="optionsQuantity[index]">
                                        <option v-for="i in nbPers()" :key="`${option.id}-${i}`" :value="i">
                                            {{ i }}
                                        </option>
                                    </select>
                                    <span style="font-size: 13px">
                                        {{ option.name }} -
                                        {{ option.include ? "Inclus" : $utils.formatPrice(option.price) + "€" }}
                                    </span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="product-infos-modal mt-4 pt-3">
                <div class="row mb-2">
                    <div class="col-5">Validité</div>
                    <div class="col-7">{{ product.validity }} jours</div>
                </div>
                <div class="row mb-2">
                    <div class="col-5">Nombre de personne(s)</div>
                    <div class="col-7">
                        {{ product.nb_pers }}
                    </div>
                </div>
                <div class="row" v-if="product.terms_of_use">
                    <div class="col-5">Conditions d'utilisation</div>
                    <div class="col-7">
                        {{ product.terms_of_use }}
                    </div>
                </div>
            </div>
        </div>
        <div slot="footer" class="p-3 footer-modal">
            <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">
                <div></div>
                <div>
                    <div class="product-price d-inline-block mr-3">
                        {{ quantityData > 0 ? $utils.formatPrice(productPrice) : "--" }}€
                    </div>
                    <button
                        :disabled="!productActive || quantityData < 1"
                        @click="addToCart"
                        :style="`background: ${bgColor}`"
                        class="main-button product-modal-btn-add_product">
                        Ajouter
                    </button>
                </div>
            </div>
            <small class="text-danger" v-if="errorQuantity">{{ errorQuantity }}</small>
        </div>
    </modal>
</template>

<script>
export default {
    data() {
        return {
            quantityData: 0,
            optionsQuantityData: null,
            errorQuantity: null,
        };
    },
    props: {
        product: {
            type: Object,
            required: true,
        },
    },
    computed: {
        productPrice() {
            let amount = this.product.price * this.quantityData;
            this.optionsQuantity.forEach((optionQuantity, index) => {
                if (optionQuantity > 0 && !this.product.gv_products_options[index].include)
                    amount += this.product.gv_products_options[index].price * optionQuantity;
            });
            return amount;
        },
        bgColor() {
            return this.$store.getters["restaurant/bgColor"];
        },
        storeProduct() {
            return this.$store.getters["cart/product"];
        },
        productActive() {
            return this.product.active && this.storeProduct === null;
        },
        quantity: {
            get() {
                return this.quantityData;
            },
            set(newVal) {
                this.errorQuantity = false;
                if (isNaN(newVal)) this.errorQuantity = this.$t("errors.products.quantity.invalid");
                if (!isNaN(newVal) && newVal > 0) this.quantityData = newVal;
            },
        },
        optionsQuantity: {
            get() {
                if (this.optionsQuantityData === null)
                    this.optionsQuantityData = Array(this.product.gv_products_options.length).fill(0);
                return this.optionsQuantityData;
            },
            set(newVal) {
                this.optionsQuantityData = newVal;
            },
        },
    },
    methods: {
        nbPers() {
            return Array(this.product.nb_pers * this.quantityData + 1).keys();
        },
        addToCart() {
            let options = [];
            this.optionsQuantity.forEach((optionQuantity, index) => {
                if (optionQuantity > 0)
                    options.push({
                        option: this.product.gv_products_options[index],
                        quantity: optionQuantity,
                    });
            });
            this.$store.dispatch("cart/setProduct", {
                product: this.product,
                quantity: this.quantityData,
                options,
            });
            this.close();
        },
        close() {
            this.$emit("close");
        },
        closeFromModal: function (e) {
            e.stopPropagation();
            if (e && e.target && !e.target.classList.contains("modal-wrapper")) {
                e.stopPropagation();
            } else {
                this.close();
            }
        },
    },
};
</script>
