var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "loading", style: _vm.loadingColor }, [
    _c(
      "div",
      {
        staticClass: "spinner-grow text-blue-light",
        attrs: { role: "status" },
      },
      [
        _c("span", { staticClass: "sr-only" }, [
          _vm._v(_vm._s(_vm.$t("loading"))),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }