<template>
    <div>
        <loader-component class="w-100 text-center" v-if="loading" />
        <div class="container" v-else-if="error">
            <div class="alert alert-danger">{{ error }}</div>
        </div>
        <div v-else class="container">
            <div class="col-lg-6 col-md-8 col-12" style="max-width: 700px; margin: 0 auto">
                <Recap class="border-light b-radius-20 p-4 mb-3" :sale="sale" />
                <Pay class="border-light b-radius-20 p-4 mb-3" :sale="sale" />
            </div>
        </div>
    </div>
</template>

<script>
import LoaderComponent from "../components/LoaderComponent.vue";
import Recap from "../components/Command/Recap.vue";
import Pay from "../components/Command/Pay.vue";
import axios from "axios";

export default {
    name: "PayFromBoLayout",
    data() {
        return {
            loading: false,
            error: null,
            sale: null,
        };
    },
    computed: {
        saleToken() {
            return this.$utils.getQueryParameter("sale");
        },
    },
    methods: {
        fetchSale() {
            this.loading = true;
            this.error = null;

            axios
                .get(`${API_URL}/sales/${this.saleToken}?include=client,gv_product,gv_sale_product_options.gv_products_option`)
                .then((response) => {
                    this.sale = response.data;
                })
                .catch((error) => {
                    if (error.response && error.response.status === 404) {
                        this.error = this.$t("errors.group_payment.sale_not_found");
                    } else {
                        this.error = this.$utils.getErrorMsgFromErrorResponse(error);
                    }
                })
                .finally(() => (this.loading = false));
        },
    },
    components: {
        LoaderComponent,
        Recap,
        Pay,
    },
    created() {
        this.fetchSale();
    },
};
</script>
