<template>
    <div>
        <small class="text-danger" v-if="fatalError">{{ fatalError }}</small>
    </div>
</template>

<script>
import { DateTime } from "luxon";

export default {
    data() {
        return {
            fatalError: null,
            isPaid: false,
            interval: null,
        };
    },
    props: {
        paymentIntent: {
            type: Object,
            required: true,
        },
        client: {
            type: Object,
            required: true,
        },
        canDisplayMessage: {
            type: Boolean,
            default: false,
        },
        saleCreatedAt: {
            type: Object,
            default: function () {
                return {};
            },
        },
        timezone: {
            type: String,
            default: null,
        },
    },
    methods: {
        removeMessage() {
            const element = document.getElementById("container-payplug-custom-msg");

            if (element !== null) {
                element.remove();
            }
        },
        refreshTimer(timer) {
            const timerContainer = document.getElementById("payplug-msg-timer");

            if (timerContainer !== null) {
                timerContainer.innerHTML = timer;
            }
        },
        setCountDown() {
            this.interval = setInterval(() => {
                const diffTime = Math.ceil(DateTime.now(this.timezone).diff(this.saleCreatedAt).as("seconds"));

                const timeoutMinutes = Math.ceil(diffTime / 60);

                if (timeoutMinutes > 60) {
                    clearInterval(this.interval);
                    window.location.reload();
                }
                this.refreshTimer(Math.floor(60 - timeoutMinutes));
            }, 1000);
        },
        appendMessage() {
            const messageInnerHTML =
                "<span id='container-payplug-custom-msg'>Il vous reste <span id='payplug-msg-timer'></span> minutes pour effectuer votre paiement.<br />Passé ce délai, ce dernier ne sera plus assuré.</span>";

            const message = document.createElement("div");
            message.innerHTML = messageInnerHTML;
            message.style.color = "white";
            message.style.zIndex = 9999;
            message.style.position = "absolute";
            message.style.top = "75%";
            message.style.left = "50%";
            message.style.transform = "translateX(-50%)";
            document.body.append(message);

            this.setCountDown();
        },
        processPayment() {
            if (!this.paymentIntent || !this.paymentIntent.hosted_payment || !this.paymentIntent.hosted_payment.payment_url) {
                this.fatalError = "Can't init PayPlug";
                return;
            }

            Payplug.showPayment(this.paymentIntent.hosted_payment.payment_url);

            if (this.canDisplayMessage) {
                this.appendMessage();
            }
        },
    },
    mounted() {
        if (!Payplug) {
            this.fatalError = "Can't init PayPlug";
            return;
        }
        Payplug._listen("message", window, (e) => {
            if (typeof e.data === "string") {
                if (e.data === "closePayPlugFrame") {
                    if (this.isPaid) {
                        this.$emit("paid");
                    } else {
                        this.$emit("set-loading", false);
                        this.$emit("error");
                    }
                    clearInterval(this.interval);
                    this.removeMessage();
                }
            } else if (typeof e.data === "object") {
                if (e.data.event === "paidByPayPlug" || e.data.event === "setRedirectionUrl") {
                    this.isPaid = true;
                }
            }
        });
    },
};
</script>
