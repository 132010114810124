<template>
    <div class="product mb-3" :class="{ disable: !product.active }" @click="showModal">
        <div class="row m-0">
            <div
                v-if="product.img !== null"
                class="col-12 product-image"
                :style="`background-image: url('${product.img}')`"></div>
            <div class="product-content">
                <div class="col-12 pt-3 pb-2 product-infos">
                    <h6 class="product-title noshow-widget-font-title" :style="`color: ${bgColor}`">
                        {{ product.name }}
                    </h6>
                    <p
                        class="product-description"
                        :style="'overflow:hidden;' + (product.img !== null ? 'max-height:5.2em;' : '')">
                        {{ getSubDescription(product.description, product.img !== null ? 60 : 150) }}
                    </p>
                </div>
                <div
                    class="col-12 pt-3 pb-2 product-footer d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">
                    <span class="product-price"
                        >{{
                            product.type === "amount"
                                ? $utils.getAmountPrice(product)
                                : $utils.formatPrice(product.price)
                        }}€</span
                    >
                    <button
                        :style="`background: ${bgColor}`"
                        class="main-button main-button-sm product-modal-btn-add_product"
                        :disabled="!productActive">
                        {{ product.active ? "Ajouter" : "Indisponible" }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        product: {
            type: Object,
            required: true,
        },
    },
    computed: {
        bgColor() {
            return this.$store.getters["restaurant/bgColor"];
        },
        storeProduct() {
            return this.$store.getters["cart/product"];
        },
        productActive() {
            return this.product.active && this.storeProduct === null;
        },
    },
    methods: {
        getSubDescription(description, max_length) {
            if (description === null) return "";
            return (description.substring(0, max_length) + (description.length > max_length ? "..." : "")).replace(
                /\n+/g,
                " "
            );
        },
        showModal() {
            if (this.storeProduct !== null) this.$emit("show-cart-full-modal");
            else if (this.product.active) {
                if (this.product.type === "menu") this.$emit("show-menu-modal", this.product);
                else if (this.product.type === "amount") {
                    if (this.product.predefined_amounts.length === 1 && !this.product.allow_custom_amounts)
                        this.$emit("show-one-amount-modal", this.product);
                    else this.$emit("show-choice-amount-modal", this.product);
                }
            }
        },
    },
};
</script>
