<template>
    <div>
        <loader-component class="w-100 text-center" v-if="loading > 0" />
        <div class="container" v-else-if="error">
            <div class="alert alert-danger" v-if="error">{{ error }}</div>
        </div>
        <div v-else>
            <payment-group-list-emails :sale="sale" v-if="customerEmail === null" @select="refreshComputed++" />
            <payment-group-payment @go-back="removeCustomerEmail" :sale="sale" v-else />
        </div>
    </div>
</template>

<script>
import LoaderComponent from "../components/LoaderComponent.vue";
import PaymentGroupListEmails from "../components/GroupPayment/PaymentGroupListEmails.vue";
import PaymentGroupPayment from "../components/GroupPayment/PaymentGroupPayment.vue";
import axios from "axios";

export default {
    name: "GroupPaymentLayout",
    data() {
        return {
            loading: false,
            error: null,
            sale: null,
            refreshComputed: 0,
        };
    },
    computed: {
        saleToken() {
            return this.$utils.getQueryParameter("sale");
        },
        customerEmail() {
            this.refreshComputed;
            return this.$utils.getQueryParameter("email");
        },
    },
    methods: {
        fetchSale() {
            this.loading = true;
            this.error = null;

            axios
                .get(
                    `${API_URL}/sales/${this.saleToken}?include=group_payments,client,gv_product,gv_sale_product_options.gv_products_option&with_trashed=1`
                )
                .then((response) => {
                    this.loading = false;
                    this.sale = response.data;
                })
                .catch((error) => {
                    this.loading = false;
                    if (error.response && error.response.status === 404)
                        this.error = this.$t("errors.group_payment.sale_not_found");
                    else if (error.response && error.response.data.message) this.error = error.response.data.message;
                    else if (error.message) this.error = error.message;
                    else this.error = this.$t("errors.common.unknown");
                });
        },
        removeCustomerEmail() {
            let url = this.$utils.removeQueryParameter("email");
            window.history.pushState({ path: url }, "", url);
            this.refreshComputed++;
            this.fetchSale();
        },
    },
    created() {
        this.fetchSale();
    },
    components: {
        LoaderComponent,
        PaymentGroupListEmails,
        PaymentGroupPayment,
    },
};
</script>
