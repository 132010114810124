var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "cart p-3" }, [
      _c(
        "h5",
        {
          staticClass: "noshow-widget-font-title",
          style: `color: ${_vm.bgColor}`,
        },
        [_vm._v("Panier")]
      ),
      _vm._v(" "),
      _c("small", [
        _vm._v(
          "Information : si vous souhaitez commander plusieurs bons cadeaux, merci d'effectuer plusieurs commandes."
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "cart-body mb-3" }, [
        _c("div", { staticClass: "cart-product pt-1 pb-1 div-cart-price" }, [
          _vm.product !== null && _vm.product.product.type === "menu"
            ? _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-3 pr-1" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.product.quantity,
                        expression: "product.quantity",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { type: "number", min: 1 },
                    domProps: { value: _vm.product.quantity },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.product, "quantity", $event.target.value)
                      },
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-6 pl-1 pr-1" }, [
                  _c(
                    "p",
                    [
                      _c("strong", [_vm._v(_vm._s(_vm.product.product.name))]),
                      _c("br"),
                      _vm._v(" "),
                      _vm._l(_vm.product.options, function (option) {
                        return _c(
                          "small",
                          {
                            key: option.option.id,
                            staticClass: "ml-2 d-block",
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(option.quantity) +
                                " x " +
                                _vm._s(option.option.name) +
                                "\n                            "
                            ),
                          ]
                        )
                      }),
                    ],
                    2
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-3 pl-1 text-right" }, [
                  _c("p", [
                    _c("strong", { staticClass: "cart-price" }, [
                      _vm._v(_vm._s(_vm.price) + "€"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass:
                          "btn btn-sm text-danger p-0 pl-1 float-right btn-delete-item",
                        attrs: { type: "button" },
                        on: { click: _vm.deleteCart },
                      },
                      [_c("i", { staticClass: "fas fa-times" })]
                    ),
                  ]),
                ]),
              ])
            : _vm.product !== null && _vm.product.product.type === "amount"
            ? _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-9 pl-3" }, [
                  _c("p", [
                    _c("strong", [_vm._v(_vm._s(_vm.product.product.name))]),
                    _c("br"),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-3 pl-1 text-right" }, [
                  _c("p", [
                    _c("strong", { staticClass: "cart-price" }, [
                      _vm._v(_vm._s(_vm.price) + "€"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass:
                          "btn btn-sm text-danger p-0 pl-1 float-right btn-delete-item",
                        attrs: { type: "button" },
                        on: { click: _vm.deleteCart },
                      },
                      [_c("i", { staticClass: "fas fa-times" })]
                    ),
                  ]),
                ]),
              ])
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c("textarea", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.comment,
            expression: "comment",
          },
        ],
        staticClass: "form-control mb-2",
        attrs: { rows: "4", placeholder: "Note pour l'établissement" },
        domProps: { value: _vm.comment },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.comment = $event.target.value
          },
        },
      }),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "main-button",
          style: `background-color: ${_vm.bgColor}; width: 100%;`,
          attrs: { disabled: _vm.price == "--" || !_vm.hasCardPaymentSystem },
          on: {
            click: function ($event) {
              return _vm.$emit("show-modal")
            },
          },
        },
        [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-6 text-left" }, [_vm._v("Paiement")]),
            _vm._v(" "),
            _c("div", { staticClass: "col-6 text-right" }, [
              _vm._v(_vm._s(_vm.price) + "€"),
            ]),
          ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }