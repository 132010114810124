var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.loadings.isGeneralLoading
        ? _c("LoaderComponent", { staticClass: "w-100 text-center" })
        : [
            _vm.error
              ? _c("div", { staticClass: "alert alert-danger" }, [
                  _vm._v(_vm._s(_vm.error)),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "row mt-3" },
              [
                _c("div", { staticClass: "col-xl-9 col-lg-8" }, [
                  _c(
                    "div",
                    [
                      _c(
                        "div",
                        {
                          staticClass: "row section-gift",
                          attrs: { id: "gifts" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-12 py-4 d-flex justify-content-center align-items-center",
                            },
                            [
                              _vm.config && _vm.config.display_categories
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex justify-content-center align-items-center pointer",
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          $event.stopPropagation()
                                          _vm.showCategories = true
                                        },
                                      },
                                    },
                                    [
                                      _c("feather", {
                                        staticClass: "mr-2",
                                        attrs: { type: "menu" },
                                      }),
                                      _vm._v(
                                        "\n                                Catégories\n                            "
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "h5",
                                {
                                  staticClass:
                                    "noshow-widget-font-title noshow-widget-title text-uppercase d-flex justify-content-center align-items-center flex-grow-1",
                                },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(
                                        _vm.currentCategory
                                          ? _vm.currentCategory.name
                                          : "Bons Cadeaux"
                                      ) +
                                      "\n                            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _vm.showCategories
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "list-categories pb-5 shadow-sm",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex align-items-center px-3 py-3",
                                        },
                                        [
                                          _c("feather", {
                                            staticClass: "pointer mr-3",
                                            attrs: { type: "x" },
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                $event.stopPropagation()
                                                _vm.showCategories = false
                                              },
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "h6",
                                            {
                                              staticClass:
                                                "text-uppercase mb-0 px-3",
                                            },
                                            [_vm._v("Catégories")]
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "ul",
                                        { staticClass: "p-0" },
                                        _vm._l(
                                          _vm.categories,
                                          function (category) {
                                            return _c(
                                              "li",
                                              {
                                                key: category.id,
                                                staticClass:
                                                  "py-2 pointer list-category-item text-capitalize px-3",
                                                on: {
                                                  click: function ($event) {
                                                    _vm.currentCategory =
                                                      category
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                        " +
                                                    _vm._s(category.name) +
                                                    "\n                                    "
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.loadings.isProductsLoading
                        ? _c("LoaderComponent", {
                            staticClass: "w-100 text-center",
                          })
                        : _c("div", { staticClass: "row mt-3" }, [
                            _c(
                              "div",
                              {
                                staticClass: "col-12",
                                attrs: { id: "gifts_content" },
                              },
                              [
                                _vm.products === null ||
                                _vm.products.length === 0
                                  ? _c("div", [
                                      _c(
                                        "h5",
                                        { staticClass: "text-center m-5" },
                                        [_vm._v("Aucun produit trouvé")]
                                      ),
                                    ])
                                  : _vm._l(_vm.products, function (product) {
                                      return _c("ProductCardComponent", {
                                        key: product.id,
                                        attrs: { product: product },
                                        on: {
                                          "show-menu-modal": function ($event) {
                                            _vm.showMenuModal = $event
                                          },
                                          "show-one-amount-modal": function (
                                            $event
                                          ) {
                                            _vm.showOneAmountModal = $event
                                          },
                                          "show-choice-amount-modal": function (
                                            $event
                                          ) {
                                            _vm.showChoiceAmountModal = $event
                                          },
                                          "show-cart-full-modal": function (
                                            $event
                                          ) {
                                            _vm.showCartFullModal = true
                                          },
                                        },
                                      })
                                    }),
                              ],
                              2
                            ),
                          ]),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("CartComponent", {
                  staticClass: "col-xl-3 col-lg-4",
                  on: {
                    "show-modal": function ($event) {
                      _vm.showPaymentModal = true
                    },
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _vm.showMenuModal !== false
              ? _c("MenuModal", {
                  attrs: { product: _vm.showMenuModal },
                  on: {
                    close: function ($event) {
                      _vm.showMenuModal = false
                    },
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.showOneAmountModal !== false
              ? _c("OneAmountModal", {
                  attrs: { product: _vm.showOneAmountModal },
                  on: {
                    close: function ($event) {
                      _vm.showOneAmountModal = false
                    },
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.showChoiceAmountModal !== false
              ? _c("ChoiceAmountModal", {
                  attrs: { product: _vm.showChoiceAmountModal },
                  on: {
                    close: function ($event) {
                      _vm.showChoiceAmountModal = false
                    },
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.showPaymentModal !== false
              ? _c("PaymentModal", {
                  on: {
                    close: function ($event) {
                      _vm.showPaymentModal = false
                    },
                    paid: _vm.paid,
                    saved: _vm.saved,
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.showCartFullModal !== false
              ? _c("CartFullModal", {
                  on: {
                    close: function ($event) {
                      _vm.showCartFullModal = false
                    },
                  },
                })
              : _vm._e(),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }