<template>
    <div>
        <div class="row">
            <div class="col-12">
                <template v-if="!paid">
                    <span class="d-block text-danger" v-if="error">{{ error }}</span>
                    <span class="d-block text-danger" v-else-if="alreadyPaid">{{
                        $t("errors.common.already_paid")
                    }}</span>
                    <template v-else>
                        <p v-if="canPay">Renseignez vos informations de carte bancaire</p>
                        <p v-else class="text-danger">{{ $t("errors.common.cant_be_pay") }}</p>
                        <LoaderComponent class="w-100 text-center" v-if="loadingPayment" />
                        <template v-if="canPay">
                            <StripePaymentForm
                                class="col-12"
                                v-if="paymentIntentType === CARD_PAYMENT_SYSTEM_STRIPE.value"
                                ref="stripePaymentForm"
                                :paymentIntent="paymentIntent"
                                :client="client"
                                :stripe_client_id="stripe_client_id"
                                @set-loading="loadingPayment = $event"
                                @paid="paid = true" />
                            <PayPlugPaymentForm
                                class="col-12"
                                v-if="paymentIntentType === CARD_PAYMENT_SYSTEM_PAYPLUG.value"
                                ref="payplugPaymentForm"
                                :paymentIntent="paymentIntent"
                                :client="client"
                                @set-loading="loadingPayment = $event"
                                @paid="paid = true" />
                        </template>
                        <span class="d-block text-danger" v-if="errorCard">{{ errorCard }}</span>
                        <div class="w-100 text-right">
                            <button
                                v-if="canPay"
                                :disabled="loadingPayment"
                                @click="processPayment"
                                class="mt-2 btn main-button"
                                :style="{ 'background-color': `${bgColor} !important` }">
                                Payer
                            </button>
                        </div>
                    </template>
                </template>
                <div v-else>Votre paiement a bien été pris en compte !</div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from "../../plugins/moment";
import LoaderComponent from "../LoaderComponent.vue";
import axios from "axios";
import StripePaymentForm from "../PaymentForms/Stripe.vue";
import PayPlugPaymentForm from "../PaymentForms/PayPlug.vue";
import CardPaymentSystemEnum from "../../mixins/enums/CardPaymentSystemEnum.js";

export default {
    data() {
        return {
            loadingPayment: false,
            error: null,
            errorCard: null,
            paymentIntent: null,
            paymentIntentType: null,
            paid: false,
        };
    },
    mixins: [CardPaymentSystemEnum],
    props: {
        sale: {
            type: Object,
            required: true,
        },
    },
    computed: {
        bgColor() {
            return this.$store.getters["restaurant/bgColor"];
        },
        alreadyPaid() {
            return this.sale.status === "captured";
        },
        canPay() {
            return this.sale.status === "pending" && !this.paid;
        },
        stripe_client_id() {
            return this.$store.getters["restaurant/stripeClientId"];
        },
        client() {
            return {
                email: this.sale.client.email,
            };
        },
    },
    methods: {
        fetchPaymentIntent() {
            this.loadingPayment = true;
            this.error = null;

            axios
                .post(`${API_URL}/${this.$api_key}/getPaymentIntent/${this.sale.token}`)
                .then((response) => {
                    this.loadingPayment = false;
                    this.paymentIntentType = response.data.type;
                    this.paymentIntent = response.data.setup_intent;
                })
                .catch((error) => {
                    this.loadingPayment = false;
                    if (error.response && error.response.data.message) this.error = error.response.data.message;
                    else if (error.message) this.error = error.message;
                    else this.error = this.$t("errors.common.unknown");
                });
        },
        processPayment() {
            this.errorCard = null;
            if (!this.paymentIntent || !this.canPay) {
                this.errorCard = this.$t("errors.common.already_paid");
                return;
            }
            this.$refs[`${this.paymentIntentType}PaymentForm`].processPayment();
        },
    },
    components: {
        LoaderComponent,
        StripePaymentForm,
        PayPlugPaymentForm,
    },
    mounted() {
        if (this.canPay) this.fetchPaymentIntent();
    },
};
</script>
