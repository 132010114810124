<template>
    <small :class="errClass" v-if="hasErrors" v-html="joinErrors"></small>
</template>

<script>
export default {
    name: "ShowErrors",
    props: {
        errors: {
            required: true,
        },
        errorKey: {
            type: String,
            required: true,
        },
        errClass: {
            type: String,
            default: "text-danger",
        },
    },
    computed: {
        hasErrors() {
            return this.errors && this.errors[this.errorKey] && this.errors[this.errorKey].length > 0;
        },
        joinErrors() {
            return this.errors[this.errorKey].join("<br/>");
        },
    },
};
</script>
