<template>
    <div>
        <div id="stripeCardForm" class="p-3 border mt-2"></div>
        <small class="text-danger" v-if="errorCard">{{ errorCard }}</small>
    </div>
</template>

<script>
export default {
    data() {
        return {
            errorCard: null,
        };
    },
    props: {
        paymentIntent: {
            default: null,
        },
        client: {
            type: Object,
            required: true,
        },
        stripe_client_id: {
            type: String,
            required: true,
        },
    },
    methods: {
        processPayment() {
            this.errorCard = null;
            this.$emit("set-loading", true);

            let billing_details = {};
            if (this.client.email) billing_details.email = this.client.email;
            else {
                billing_details.name = `${this.client.firstname || ""} ${this.client.lastname || ""}`;
            }

            this.stripe
                .handleCardPayment(this.paymentIntent.client_secret, this.card, {
                    payment_method_data: {
                        billing_details,
                    },
                })
                .then((result) => {
                    if (result.error) {
                        this.$emit("set-loading", false);
                        this.$emit("error");
                        this.errorCard = result.error.message;
                    } else this.$emit("paid");
                })
                .catch((error) => {
                    this.$emit("set-loading", false);
                    this.$emit("error");
                    this.errorCard = error.message || this.$t("errors.common.unknown");
                });
        },
    },
    created() {
        this.stripe = Stripe(STRIPE_API_KEY, { stripeAccount: this.stripe_client_id });
    },
    mounted() {
        this.card = this.stripe.elements().create("card");
        this.card.mount(document.getElementById("stripeCardForm"));
    },
};
</script>
