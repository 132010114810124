import axios from "axios";
let store = null;

function handleInMaintenance(response) {
    store.commit("error/setResponse", response);
    store.commit("error/setInMaintenance", true);
}

function handleLicencesErrors(response) {
    store.commit("error/setResponse", response);
    if (response && response.data && response.data.message && response.data.message.startsWith("licences.")) {
        store.commit("error/setHasLicenceError", true);
        return;
    }

    store.commit("error/setResponse", null);
}

function handleAppVersionErrors() {
    if (
        !confirm(
            "Une mise à jour importante a été effectuée. Si vous continuez, la page sera réactualisée et votre panier ne sera pas conservé."
        )
    )
        return;
    document.location.reload();
}

export default function setup(storeGiven) {
    store = storeGiven;
    axios.interceptors.response.use(
        function (response) {
            return response;
        },
        function (err) {
            if (err && err.response) {
                switch (err.response.status) {
                    case 402:
                        handleLicencesErrors(err.response);
                        break;
                    case 410:
                        handleAppVersionErrors(err.response);
                        break;
                    case 503:
                        handleInMaintenance(err.response);
                        break;
                }
            }

            return Promise.reject(err);
        }
    );
}
