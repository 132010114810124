<template>
    <div>
        <LoaderComponent class="w-100 text-center" v-if="loadings.isGeneralLoading" />
        <template v-else>
            <div class="alert alert-danger" v-if="error">{{ error }}</div>
            <div class="row mt-3">
                <div class="col-xl-9 col-lg-8">
                    <div>
                        <div class="row section-gift" id="gifts">
                            <div class="col-12 py-4 d-flex justify-content-center align-items-center">
                                <div
                                    v-if="config && config.display_categories"
                                    class="d-flex justify-content-center align-items-center pointer"
                                    @click.prevent.stop="showCategories = true">
                                    <feather type="menu" class="mr-2" />
                                    Catégories
                                </div>
                                <h5
                                    class="noshow-widget-font-title noshow-widget-title text-uppercase d-flex justify-content-center align-items-center flex-grow-1">
                                    {{ currentCategory ? currentCategory.name : "Bons Cadeaux" }}
                                </h5>
                                <div v-if="showCategories" class="list-categories pb-5 shadow-sm">
                                    <div class="d-flex align-items-center px-3 py-3">
                                        <feather type="x" class="pointer mr-3" @click.prevent.stop="showCategories = false" />
                                        <h6 class="text-uppercase mb-0 px-3">Catégories</h6>
                                    </div>
                                    <ul class="p-0">
                                        <li
                                            class="py-2 pointer list-category-item text-capitalize px-3"
                                            v-for="category in categories"
                                            :key="category.id"
                                            @click="currentCategory = category">
                                            {{ category.name }}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <LoaderComponent class="w-100 text-center" v-if="loadings.isProductsLoading" />
                        <div v-else class="row mt-3">
                            <div id="gifts_content" class="col-12">
                                <div v-if="products === null || products.length === 0">
                                    <h5 class="text-center m-5">Aucun produit trouvé</h5>
                                </div>
                                <template v-else>
                                    <ProductCardComponent
                                        v-for="product in products"
                                        :key="product.id"
                                        :product="product"
                                        @show-menu-modal="showMenuModal = $event"
                                        @show-one-amount-modal="showOneAmountModal = $event"
                                        @show-choice-amount-modal="showChoiceAmountModal = $event"
                                        @show-cart-full-modal="showCartFullModal = true" />
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
                <CartComponent class="col-xl-3 col-lg-4" @show-modal="showPaymentModal = true" />
            </div>
            <MenuModal v-if="showMenuModal !== false" :product="showMenuModal" @close="showMenuModal = false" />
            <OneAmountModal v-if="showOneAmountModal !== false" :product="showOneAmountModal" @close="showOneAmountModal = false" />
            <ChoiceAmountModal v-if="showChoiceAmountModal !== false" :product="showChoiceAmountModal" @close="showChoiceAmountModal = false" />
            <PaymentModal v-if="showPaymentModal !== false" @close="showPaymentModal = false" @paid="paid" @saved="saved" />
            <CartFullModal v-if="showCartFullModal !== false" @close="showCartFullModal = false" />
        </template>
    </div>
</template>

<script>
import LoaderComponent from "../components/LoaderComponent.vue";
import CartComponent from "../components/Cart/CartComponent.vue";
import MenuModal from "../components/Modals/MenuModal.vue";
import OneAmountModal from "../components/Modals/OneAmountModal.vue";
import ChoiceAmountModal from "../components/Modals/ChoiceAmountModal.vue";
import PaymentModal from "../components/Modals/PaymentModal.vue";
import ProductCardComponent from "../components/Products/ProductCardComponent.vue";
import CartFullModal from "../components/Modals/CartFullModal.vue";
import axios from "axios";

export default {
    name: "MainLayout",
    data() {
        return {
            isGeneralLoading: false,
            error: null,
            products: null,
            showMenuModal: false,
            showOneAmountModal: false,
            showChoiceAmountModal: false,
            showPaymentModal: false,
            showCartFullModal: false,
            showCategories: false,
            categories: [],
            currentCategory: null,
            loadings: {
                isGeneralLoading: false,
                isProductsLoading: false,
            },
        };
    },
    computed: {
        config() {
            return this.$store.getters["restaurant/config"];
        },
    },
    methods: {
        fetchCategories() {
            this.error = null;
            this.loadings.isGeneralLoading = true;

            axios
                .get(`${API_URL}/${this.$api_key}/categories`)
                .then((response) => {
                    this.categories = response.data.data;
                    this.currentCategory = this.categories[0];
                })
                .catch((error) => {
                    this.error = this.$t("errors.common.unknown");
                    if (error.response && error.response.data.message) this.error = error.response.data.message;
                    else if (error.message) this.error = error.message;
                })
                .finally(() => (this.loadings.isGeneralLoading = false));
        },
        fetchProductsByCategory(categoryId) {
            this.error = null;
            this.loadings.isProductsLoading = true;

            axios
                .get(`${API_URL}/${this.$api_key}/categories/${categoryId}?include=gvProducts`)
                .then((response) => {
                    this.products = response.data.gvProducts.data;
                })
                .catch((error) => {
                    this.error = this.$t("errors.common.unknown");
                    if (error.response && error.response.data.message) this.error = error.response.data.message;
                    else if (error.message) this.error = error.message;
                })
                .finally(() => (this.loadings.isProductsLoading = false));
        },
        fetchProducts() {
            this.error = null;
            this.loadings.isProductsLoading = true;

            axios
                .get(`${API_URL}/${this.$api_key}/products`)
                .then((response) => {
                    this.products = response.data.data;
                })
                .catch((error) => {
                    this.error = this.$t("errors.common.unknown");
                    if (error.response && error.response.data.message) this.error = error.response.data.message;
                    else if (error.message) this.error = error.message;
                })
                .finally(() => (this.loadings.isProductsLoading = false));
        },
        paid() {
            this.$store.dispatch("cart/clear");
            alert(this.$t("info.paid"));
            document.location.reload();
        },
        saved() {
            this.$store.dispatch("cart/clear");
            document.location.reload();
        },
    },
    watch: {
        currentCategory(value) {
            this.showCategories = false;

            if (value !== null) {
                this.fetchProductsByCategory(value.id);
            }
        },
    },
    components: {
        CartComponent,
        MenuModal,
        OneAmountModal,
        ChoiceAmountModal,
        PaymentModal,
        ProductCardComponent,
        CartFullModal,
        LoaderComponent,
    },
    created() {
        if (this.config && this.config.display_categories) {
            this.fetchCategories();
        } else {
            this.fetchProducts();
        }
    },
};
</script>
