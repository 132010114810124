export default {
    bgColor(state) {
        return state.bgcolor;
    },
    stripeClientId(state) {
        return state.stripe_client_id;
    },
    hasCardPaymentSystem(state) {
        return state.has_card_payment_system;
    },
    config(state) {
        return state.config;
    },
    isGroupPaymentEnable(state) {
        return state.config && state.config.group_payment;
    },
    cgv(state) {
        return state.config.cgv;
    },
    widgetFontTitle(state) {
        return state.widget_font_title;
    },
    defaultTelCountry(state) {
        return state.default_tel_country;
    },
    timezone(state) {
        return state.timezone;
    },
};
