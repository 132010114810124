<template>
    <modal @close="closeFromModal">
        <div slot="header" class="w-100">
            <div class="modal-payment-header border-bottom p-3">
                <button type="button" @click="close" class="close text-right" data-dismiss="modal" aria-label="Close">
                    <span style="color: grey" aria-hidden="true">&times;</span>
                </button>
                <h5 :style="`color: ${bgColor}`" class="mt-2 noshow-widget-font-title">Paiement</h5>
                <small id="noshow-giftvouchers-form-error-mainerror" class="text-danger d-block noshow-giftvouchers-form-error"></small>
                <small class="d-block"
                    >Pour toute information relative au délai de rétractation, merci de vous référer à nos
                    <a :href="customCgv || `${base_url}/gift_vouchers/widget/public/${this.$api_key}/cgv`" target="_blank"
                        >conditions générales de vente</a
                    >.</small
                >
            </div>
        </div>
        <div slot="body">
            <loader-component class="w-100 text-center" v-if="loading" />
            <show-errors class="d-block" :errors="errors" errorKey="main_error" />
            <form @submit="postForm" class="modal-content" v-show="step === 1">
                <div class="border-bottom p-3" v-show="!loading && !canDisplayCountdown">
                    <div class="row">
                        <div class="col-sm-3">
                            <h6 class="noshow-widget-font-title">Expédition</h6>
                        </div>
                        <div class="col-sm-9">
                            <div class="row">
                                <div class="col-12 radio">
                                    <div>
                                        <label class="mb-1">Comment recevoir mon bon cadeau ? *</label>
                                        <label class="container pr-0" style="width: inherit">
                                            <input v-model="expeditionType" value="email" type="radio" class="mt-2" />
                                            <span :style="expeditionType == 'email' ? `background-color: ${bgColor};` : ''" class="checkmark"></span>
                                            Par mail (format PDF)
                                        </label>
                                        <feather
                                            class="text-warning mr-3"
                                            style="width: 1.1em"
                                            type="info"
                                            v-tooltip="{
                                                content: 'Le mail sera envoyé dès la confirmation de votre paiement',
                                                triggers: ['touch', 'hover'],
                                            }" />
                                        <label v-if="isPostalAvailable" class="container" style="width: inherit">
                                            <input v-model="expeditionType" value="postal_mail" type="radio" class="mt-2" />
                                            <span
                                                :style="expeditionType == 'postal_mail' ? `background-color: ${bgColor};` : ''"
                                                class="checkmark"></span>
                                            Par courrier
                                        </label>
                                    </div>
                                    <show-errors :errors="errors" errorKey="expedition_type" />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 radio">
                                    <div>
                                        <label class="mb-1">Destinataire *</label>
                                        <label class="container" style="width: inherit">
                                            <input v-model="hasBeneficiary" :value="true" type="radio" class="mt-2 mr-1" />
                                            <span :style="hasBeneficiary ? `background-color: ${bgColor};` : ''" class="checkmark"></span>
                                            Pour offrir
                                        </label>
                                        <label class="container" style="width: inherit">
                                            <input v-model="hasBeneficiary" :value="false" type="radio" class="mt-2 mr-1" />
                                            <span :style="!hasBeneficiary ? `background-color: ${bgColor};` : ''" class="checkmark"></span>
                                            Pour moi-même
                                        </label>
                                    </div>
                                    <show-errors :errors="errors" errorKey="has_beneficiary" />
                                </div>
                            </div>
                            <template v-if="hasBeneficiary">
                                <div class="row">
                                    <div class="col-sm-6">
                                        <label
                                            >Nom du bénéficiaire *
                                            <input v-model="beneficiary.beneficiary_lastname" type="text" class="form-control mt-2" />
                                        </label>
                                        <show-errors :errors="errors" errorKey="beneficiary_lastname" />
                                    </div>
                                    <div class="col-sm-6">
                                        <label
                                            >Prénom du bénéficiaire *
                                            <input v-model="beneficiary.beneficiary_firstname" type="text" class="form-control mt-2" />
                                        </label>
                                        <show-errors :errors="errors" errorKey="beneficiary_firstname" />
                                    </div>
                                </div>
                                <div class="row mt-3" v-if="expeditionType === 'postal_mail'">
                                    <div class="col-12">
                                        <label class="container">
                                            <input type="checkbox" v-model="deliver_to_beneficiary" />
                                            <span
                                                class="checkmark"
                                                :style="deliver_to_beneficiary ? `background-color: ${bgColor} !important;` : ''"></span
                                            >Envoyer au bénéficiare
                                        </label>
                                        <ShowErrors :errors="errors" errorKey="deliver_to_beneficiary" />
                                    </div>
                                </div>
                                <template v-if="expeditionType === 'email'">
                                    <div class="row">
                                        <div class="col-12">
                                            <label
                                                >Mail de réception du bon cadeau *
                                                <input v-model="beneficiary.beneficiary_email" type="email" class="form-control mt-2" />
                                            </label>
                                            <show-errors :errors="errors" errorKey="beneficiary_email" />
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12">
                                            <label>Message personnalisé à ajouter au mail (optionnel)</label>
                                            <textarea v-model="beneficiary.beneficiary_message" rows="5" class="form-control"></textarea>
                                            <show-errors :errors="errors" errorKey="beneficiary_message" />
                                        </div>
                                    </div>
                                </template>
                                <template v-if="expeditionType === 'postal_mail' && deliver_to_beneficiary">
                                    <div class="row mt-2">
                                        <div class="col-12">
                                            <label>
                                                Adresse d'expédition *
                                                <postal-address-autocomplete
                                                    v-model="beneficiaryAddress"
                                                    :is-international-available="isInternationalAvailable" />
                                            </label>
                                        </div>
                                    </div>
                                </template>
                            </template>
                        </div>
                    </div>
                </div>
                <div class="p-3 border-bottom" v-show="!loading && !canDisplayCountdown">
                    <div class="row">
                        <div class="col-sm-3">
                            <h6 class="noshow-widget-font-title">Mes coordonnées</h6>
                        </div>
                        <div class="col-sm-9">
                            <div class="row">
                                <div class="col-12 radio">
                                    <div>
                                        <label class="mb-1">Civilité *</label>
                                        <label class="container" style="width: inherit">
                                            <input v-model="client.civility" value="monsieur" type="radio" class="mt-2 mr-1" />
                                            <span
                                                class="checkmark"
                                                :style="client.civility == 'monsieur' ? `background-color: ${bgColor};` : ''"></span>
                                            Monsieur
                                        </label>
                                        <label class="container" style="width: inherit">
                                            <input v-model="client.civility" value="madame" type="radio" class="mt-2 mr-1" />
                                            <span
                                                class="checkmark"
                                                :style="client.civility == 'madame' ? `background-color: ${bgColor};` : ''"></span>
                                            Madame
                                        </label>
                                    </div>
                                    <show-errors :errors="errors" errorKey="civility" />
                                </div>
                                <div class="col-sm-6">
                                    <label
                                        >Nom *
                                        <input required v-model="client.lastname" type="text" class="form-control mt-2" />
                                    </label>
                                    <show-errors :errors="errors" errorKey="lastname" />
                                </div>
                                <div class="col-sm-6">
                                    <label
                                        >Prénom *
                                        <input required v-model="client.firstname" type="text" class="form-control mt-2" />
                                    </label>
                                    <show-errors :errors="errors" errorKey="firstname" />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-6">
                                    <label
                                        >Email *
                                        <input required v-model="client.email" type="email" class="form-control mt-2" />
                                    </label>
                                    <show-errors :errors="errors" errorKey="email" />
                                </div>
                                <div class="col-sm-6">
                                    <label>Numéro de téléphone *</label>
                                    <vue-tel-input
                                        name="telInput"
                                        :defaultCountry="defaultTelCountry"
                                        @validate="setPhoneAndCountry"
                                        v-model="tel_raw"
                                        :enabledCountryCode="true"
                                        inputClasses="m-0 border-0"
                                        mode="international"
                                        placeholder="-- -- -- -- --"
                                        required="required"></vue-tel-input>
                                    <show-errors :errors="errors" errorKey="tel" />
                                </div>
                            </div>
                            <template v-if="(!hasBeneficiary || !deliver_to_beneficiary) && expeditionType === 'postal_mail'">
                                <div class="row">
                                    <div class="col-12">
                                        <label>
                                            Adresse d'expédition *
                                            <postal-address-autocomplete
                                                v-model="clientAddress"
                                                :is-international-available="isInternationalAvailable" />
                                        </label>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
                <div class="p-3 border-bottom" v-show="!loading">
                    <div class="row">
                        <div class="col-sm-3">
                            <h6 class="noshow-widget-font-title">Paiement</h6>
                        </div>
                        <div class="col-sm-9">
                            <div class="row mt-3" v-if="isGroupPaymentAvailable && !token">
                                <div class="col-12">
                                    <label class="container">
                                        <input type="checkbox" v-model="isGroupPayment" />
                                        <span class="checkmark" :style="isGroupPayment ? `background-color: ${bgColor} !important;` : ''"></span
                                        >Paiement de groupe
                                    </label>
                                    <ShowErrors :errors="errors" errorKey="isGroupPayment" />
                                </div>
                            </div>
                            <div class="row" v-if="isGroupPayment">
                                <div class="col-12">
                                    <p class="mt-1 text-warning">Le montant minimum par personne est de 5€.</p>
                                    <label class="d-block">
                                        Votre part :
                                        <input
                                            v-model.number="ownerAmount"
                                            class="form-control mr-1 d-inline-block"
                                            style="width: 20%"
                                            type="number"
                                            min="5"
                                            step="0.01" />€
                                    </label>
                                    <ShowErrors class="d-block" :errors="errors" errorKey="ownerAmount" />
                                    <ShowErrors class="d-block" :errors="errors" errorKey="groupPayments" />
                                </div>
                                <div class="col-12" v-for="(groupPayment, index) in groupPayments" :key="index">
                                    <label>
                                        Email de la {{ getPersonNumberLabel(index) }} personne et montant à payer *
                                        <input
                                            v-model="groupPayment.email"
                                            class="form-control mt-2 d-inline-block"
                                            type="email"
                                            style="width: 60%" />
                                        <input
                                            v-model.number="groupPayment.amount"
                                            class="form-control mt-2 ml-2 d-inline-block"
                                            style="width: 20%"
                                            type="number"
                                            min="5"
                                            step="0.01" />
                                        €
                                        <button
                                            type="button"
                                            v-tooltip="{ content: 'Supprimer cette personne' }"
                                            @click="groupPayments.splice(index, 1)"
                                            class="btn btn-sm text-danger d-inline-block text-center"
                                            style="width: 10%">
                                            <i class="fas fa-times"></i>
                                        </button>
                                    </label>
                                    <ShowErrors class="d-block" :errors="errors" :errorKey="`groupPayments.${index}.email`" />
                                    <ShowErrors class="d-block" :errors="errors" :errorKey="`groupPayments.${index}.amount`" />
                                </div>
                            </div>
                            <div class="row mb-4" v-if="isGroupPayment">
                                <div class="col-12">
                                    <button
                                        type="button"
                                        v-tooltip="{ content: 'Ajouter une personne' }"
                                        @click="addGroupPayment"
                                        class="main-button main-button-sm"
                                        style="width: 40px !important; padding-left: 16px !important">
                                        <i class="fas fa-plus"></i>
                                    </button>
                                </div>
                            </div>
                            <div class="row single_payment" v-show="!isGroupPayment">
                                <div class="d-block text-center text-warning" v-if="canDisplayCountdown">
                                    <span
                                        >Il vous reste {{ remainingMinsBeforeTimeout }} minutes pour effectuer votre paiement.<br />Passé ce délai, ce
                                        dernier ne sera plus assuré.</span
                                    >
                                </div>
                                <StripePaymentForm
                                    v-if="paymentIntentType === CARD_PAYMENT_SYSTEM_STRIPE.value"
                                    class="col-12"
                                    ref="stripePaymentForm"
                                    :paymentIntent="paymentIntent"
                                    :client="client"
                                    :stripe_client_id="stripe_client_id"
                                    @set-loading="loading = $event"
                                    @error="canDisplayCountdown = true"
                                    @paid="$emit('paid', $event)" />
                                <PayPlugPaymentForm
                                    v-if="paymentIntentType === CARD_PAYMENT_SYSTEM_PAYPLUG.value"
                                    class="col-12"
                                    ref="payplugPaymentForm"
                                    :can-display-message="true"
                                    :sale-created-at="saleCreatedAt"
                                    :timezone="timezone"
                                    :paymentIntent="paymentIntent"
                                    :client="client"
                                    @set-loading="loading = $event"
                                    @error="canDisplayCountdown = true"
                                    @paid="$emit('paid', $event)" />
                            </div>
                            <div class="secure-payment text-muted mt-3">
                                <a href="https://stripe.com/fr" target="_blank"
                                    ><small><feather class="feather-18" type="lock"></feather> Paiement sécurisé</small></a
                                >
                            </div>
                        </div>
                    </div>
                </div>

                <div class="p-3">
                    <div class="row">
                        <div class="col-3">
                            <h6 class="mb-0 noshow-widget-font-title">Total</h6>
                        </div>
                        <div class="col-9">
                            <div class="row">
                                <div class="col-7 text-right mb-2">
                                    <h6 class="m-0 text-muted">Produit</h6>
                                </div>
                                <div class="col-5 text-right mb-2">
                                    <h6 :style="`color: ${bgColor} !important;`" class="mb-0 font-weight-bold">
                                        {{ $utils.formatPrice(productPrice) }}€
                                    </h6>
                                </div>
                                <div class="col-7 text-right mb-2" v-if="expeditionType === 'postal_mail'">
                                    <h6 class="m-0 text-muted">Expédition</h6>
                                </div>
                                <div class="col-5 text-right mb-2" v-if="expeditionType === 'postal_mail'">
                                    <h6 :style="`color: ${bgColor} !important;`" class="mb-0 font-weight-bold">
                                        {{ $utils.formatPrice(areaPrice) }}€
                                    </h6>
                                </div>
                                <div class="col-7 text-right mb-2">
                                    <h6 class="m-0 text-muted">Total</h6>
                                </div>
                                <div class="col-5 text-right mb-2">
                                    <h6 :style="`color: ${bgColor} !important;`" class="mb-0 font-weight-bold">
                                        {{ $utils.formatPrice(totalPrice) }}€
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="footer-modal p-3">
                    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">
                        <div>
                            <show-errors class="d-block" :errors="errors" errorKey="cart" />
                            <show-errors class="d-block" :errors="errors" errorKey="general_terms" />
                            <label class="container">
                                <input :disabled="loading" required v-model="general_terms" type="checkbox" />
                                <span
                                    class="checkmark"
                                    :class="{ disable: loading }"
                                    :style="general_terms ? `background-color: ${bgColor} !important;` : ''"></span>
                                <small class="text-muted"
                                    >Je déclare accepter les
                                    <a :href="customCgv || `${base_url}/gift_vouchers/widget/public/${this.$api_key}/cgv`" target="_blank"
                                        >conditions générales de vente</a
                                    >
                                    et de
                                    <a :href="`${base_url}/gift_vouchers/widget/public/${this.$api_key}/policy`" target="_blank"
                                        >politique de confidentialité des données personnelles</a
                                    >
                                    et y adhère sans réserve. *</small
                                >
                            </label>
                        </div>
                        <div class="payment-btn text-right">
                            <button :disabled="hasError || loading" type="submit" :style="`background: ${bgColor}`" class="main-button mt-2 mt-md-0">
                                Payer
                            </button>
                        </div>
                    </div>
                </div>
            </form>
            <div v-if="step === 2">
                <div class="w-100 p-3">
                    <div class="noshow-widget-font-title">Votre commande a bien été enregistrée !</div>
                    <div class="mt-3">
                        <p>
                            Un email a été envoyé à chaque adresse email saisie afin de procéder au paiement.<br />
                            Une fois les paiements effectués, vous recevrez un email afin de procéder à votre paiement.<br />
                            <strong>Attention, vous disposez de 30 minutes pour procéder à tous les paiements.</strong><br />
                            Vous pouvez suivre les paiements depuis ce lien ou en cliquant sur le bouton ci dessous :<br />
                        </p>

                        <p></p>
                        <div class="d-flex align-items-center mt-3">
                            <input type="text" class="form-control" readonly id="noshow-gv-txt-copy-url" :value="group_payment_url" />
                            <button type="button" @click="copyToken" class="btn" v-tooltip="currentCopyTooltip">
                                <i style="color: #666666" class="far fa-copy"></i>
                            </button>
                        </div>
                        <div class="w-100 text-center">
                            <a target="_blank" :href="group_payment_url">
                                <button class="btn main-button mt-4 btn-sm" type="button" :style="`background-color: ${bgColor} !important`">
                                    Voir la liste des paiements
                                </button>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="w-100 p-3 border-top text-right">
                    <button :disabled="loading" @click="close" class="main-button" :style="`background-color: ${bgColor} !important`">Fermer</button>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import ShowErrors from "../errors/ShowErrors.vue";
import { VueTelInput } from "vue-tel-input";
import LoaderComponent from "../LoaderComponent.vue";
import axios from "axios";
import StripePaymentForm from "../PaymentForms/Stripe.vue";
import PayPlugPaymentForm from "../PaymentForms/PayPlug.vue";
import CardPaymentSystemEnum from "../../mixins/enums/CardPaymentSystemEnum.js";
import { DateTime } from "luxon";
import PostalAddressAutocomplete from "../forms/PostalAddressAutocomplete.vue";

export default {
    data() {
        return {
            loading: false,
            isGroupPayment: false,
            hasBeneficiary: true,
            deliver_to_beneficiary: false,
            expeditionType: "email",
            errors: null,
            errorCard: null,
            tel_raw: this.$store.getters["client/tel"],
            beneficiary: {
                beneficiary_email: null,
                beneficiary_firstname: null,
                beneficiary_lastname: null,
                beneficiary_address: null,
                beneficiary_postal_code: null,
                beneficiary_city: null,
                beneficiary_country: "France",
                beneficiary_message: "",
            },
            beneficiaryAddress: null,
            general_terms: false,
            stripe: null,
            card: null,
            token: null,
            paymentIntent: null,
            paymentIntentType: null,
            groupPayments: [],
            ownerAmount: null,
            currentCopyTooltip: { content: "Copier" },
            group_payment_url: null,
            canDisplayCountdown: false,
            saleCreatedAt: null,
            timeoutMinutes: 0,
        };
    },
    mixins: [CardPaymentSystemEnum],
    computed: {
        clientAddress: {
            get() {
                return this.$store.getters["client/address"] === null
                    ? null
                    : {
                          locality: this.$store.getters["client/city"],
                          country: this.$store.getters["client/country"],
                          street_address: this.$store.getters["client/address"],
                          postal_code: this.$store.getters["client/postal_code"],
                          region: null,
                      };
            },
            set(value) {
                this.$store.dispatch("client/setAddress", value && value.street_address);
                this.$store.dispatch("client/setPostalCode", value && value.postal_code);
                this.$store.dispatch("client/setCity", value && value.locality);
                this.$store.dispatch("client/setCountry", (value && value.country) ?? "France");
            },
        },
        remainingMinsBeforeTimeout() {
            return Math.floor(60 - this.timeoutMinutes);
        },
        defaultTelCountry() {
            return this.$store.getters["restaurant/defaultTelCountry"];
        },
        customCgv() {
            return this.$store.getters["restaurant/cgv"];
        },
        step() {
            if (this.isGroupPayment && this.token !== null) return 2;
            return 1;
        },
        isGroupPaymentAvailable() {
            return this.$store.getters["restaurant/isGroupPaymentEnable"];
        },
        hasError() {
            return this.expeditionTypeError !== false || this.totalPrice === "--";
        },
        expeditionTypeError() {
            if (this.expeditionType !== "postal_mail") return false;
            let country = this.hasBeneficiary ? this.beneficiary.beneficiary_country : this.client.country;
            country = country != null ? country.toLowerCase() : "";
            if (country === "france") return !this.isFranceAvailable ? this.$t("errors.expedition.area_not_available") : false;
            return !this.isInternationalAvailable ? this.$t("errors.expedition.area_not_available") : false;
        },
        currentArea() {
            if (this.expeditionType !== "postal_mail") return false;
            let country = this.hasBeneficiary ? this.beneficiary.beneficiary_country : this.client.country;
            country = country != null ? country.toLowerCase() : "";
            if (!country) return null;
            if (country === "france") return this.searchArea("fr");
            return this.searchArea("int");
        },
        base_url() {
            return NOSHOW_URL;
        },
        bgColor() {
            return this.$store.getters["restaurant/bgColor"];
        },
        stripe_client_id() {
            return this.$store.getters["restaurant/stripeClientId"];
        },
        config() {
            return this.$store.getters["restaurant/config"];
        },
        timezone() {
            return this.$store.getters["restaurant/timezone"];
        },
        isPostalAvailable() {
            return this.config !== null && this.config.postal_mail && this.config.dispatch_areas && this.config.dispatch_areas.length > 0;
        },
        isFranceAvailable() {
            return this.searchArea("fr") !== null;
        },
        isInternationalAvailable() {
            return this.searchArea("int") !== null;
        },
        client: {
            get() {
                return this.$store.getters["client/client"];
            },
            set(newVal) {
                this.$store.dispatch("client/setClient", newVal);
            },
        },
        product() {
            return this.$store.getters["cart/product"];
        },
        builtData() {
            return {
                cart: {
                    gv_product_id: this.product.product.id,
                    quantity: this.product.quantity || 1,
                    amount: this.productPrice,
                    product_options: this.product.options
                        ? this.product.options.map((o) => {
                              return { id: o.option.id, quantity: o.quantity };
                          })
                        : null,
                },
                expedition_type: this.expeditionType,
                has_beneficiary: this.hasBeneficiary,
                general_terms: this.general_terms,
                comment_public: this.$store.getters["cart/comment"],
                isGroupPayment: this.isGroupPayment,
                deliver_to_beneficiary: this.deliver_to_beneficiary,
                groupPayments: this.groupPayments.map((item) => {
                    let data = {
                        ...item,
                    };
                    data.amount = data.amount ? data.amount * 100 : null;
                    return data;
                }),
                ownerAmount: this.ownerAmount ? this.ownerAmount * 100 : null,
                ...this.client,
                ...this.beneficiary,
            };
        },
        areaPrice() {
            return this.currentArea ? Number.parseFloat(this.currentArea.fees) : "--";
        },
        productPrice() {
            if (!this.product) {
                return "--";
            }

            if (this.product.product.type === "menu") {
                return this.product.options
                    .filter((option) => !option.option.include)
                    .reduce(
                        (amount, option) => amount + Number.parseFloat(option.option.price) * Number.parseInt(option.quantity),
                        Number.parseFloat(this.product.product.price) * Number.parseInt(this.product.quantity)
                    );
            }

            if (this.product.product.type === "amount") {
                if (this.product.product.predefined_amounts.length === 1 && !this.product.product.allow_custom_amounts) {
                    return Number.parseFloat(this.product.product.predefined_amounts[0]);
                }

                if (Object.keys(this.product).includes("amount")) {
                    return Number.parseFloat(this.product.amount);
                }
            }

            return "--";
        },
        totalPrice() {
            if (this.productPrice === "--") {
                return "--";
            }

            if (this.expeditionType === "postal_mail") {
                if (this.areaPrice === "--") {
                    return "--";
                }

                return this.areaPrice + this.productPrice;
            }

            return this.productPrice;
        },
        totalGroupAmount() {
            return (
                this.groupPayments
                    .filter((payment) => payment.amount !== null && payment.amount !== "" && !Number.isNaN(payment.amount))
                    .reduce(
                        (totalAmount, payment) => totalAmount + Number.parseInt(payment.amount * 100),
                        this.ownerAmount !== null && this.ownerAmount !== "" && !Number.isNaN(this.ownerAmount)
                            ? Number.parseInt(this.ownerAmount * 100)
                            : 0
                    ) / 100
            );
        },
    },
    methods: {
        setCountDown() {
            const interval = setInterval(() => {
                const timeout = Math.ceil(DateTime.now(this.timezone).diff(this.saleCreatedAt).as("seconds"));

                this.timeoutMinutes = Math.ceil(timeout / 60);

                if (this.timeoutMinutes > 60) {
                    clearInterval(interval);
                    window.location.reload();
                }
            }, 1000);
        },
        getPersonNumberLabel(nb) {
            let labels = {
                0: "deuxième",
                1: "troisième",
                2: "quatrième",
                3: "cinquième",
                4: "sixième",
                5: "septième",
                6: "huitième",
                7: "neuvième",
                8: "dixième",
            };
            return labels[nb] || "";
        },
        addGroupPayment() {
            if (this.groupPayments.length >= 9) return;
            if (this.totalPrice === "--") return;
            this.groupPayments.push({
                email: null,
                amount: (Number.parseInt(this.totalPrice * 100) - Number.parseInt(this.totalGroupAmount * 100)) / 100 || 0,
            });
        },
        postForm(e) {
            e.stopPropagation();
            e.preventDefault();
            this.loading = true;
            this.errors = null;

            if (this.paymentIntent) return this.processPayment();
            if (this.token) return this.getPaymentIntent();

            axios
                .post(`${API_URL}/${this.$api_key}/cart`, this.builtData)
                .then((response) => {
                    this.token = response.data.token;
                    this.saleCreatedAt = DateTime.fromISO(response.data.date_time);
                    this.setCountDown();
                    if (!this.isGroupPayment) this.getPaymentIntent();
                    else {
                        this.group_payment_url = response.data.group_payment_url;
                        this.loading = false;
                    }
                })
                .catch((error) => {
                    this.loading = false;
                    if (error.response && error.response.data.errors) this.errors = error.response.data.errors;
                    else if (error.response && error.response.data.message) this.errors = { main_error: [error.response.data.message] };
                    else if (error.message) this.errors = { main_error: [error.message] };
                    else this.errors = { main_error: [this.$t("errors.common.unknown")] };
                });
        },
        getPaymentIntentType() {
            this.paymentIntentType = null;
            this.loading = true;

            axios
                .get(`${API_URL}/${this.$api_key}/cardPaymentSystemType`)
                .then((response) => {
                    this.paymentIntentType = response.data.type;
                    this.loading = false;
                })
                .catch(() => (this.loading = false));
        },
        getPaymentIntent() {
            this.errors = null;

            axios
                .post(`${API_URL}/${this.$api_key}/getPaymentIntent/${this.token}`)
                .then((response) => {
                    this.paymentIntentType = response.data.type;
                    this.paymentIntent = response.data.setup_intent;
                    this.$nextTick(() => {
                        this.processPayment();
                    });
                })
                .catch((error) => {
                    this.loading = false;
                    if (error.response && error.response.data.message) this.errors = { main_error: [error.response.data.message] };
                    else if (error.message) this.errors = { main_error: [error.message] };
                    else this.errors = { main_error: [this.$t("errors.common.unknown")] };
                });
        },
        processPayment() {
            this.$refs[`${this.paymentIntentType}PaymentForm`].processPayment();
        },
        setPhoneAndCountry(phoneObject) {
            if (phoneObject.isValid) {
                this.client.tel = phoneObject.number.international;
                this.client.tel_country = phoneObject.regionCode;
            } else {
                this.client.tel = null;
                this.client.tel_country = null;
            }
        },
        searchArea(search) {
            if (this.config === null || this.config.postal_mail !== 1 || !this.config.dispatch_areas || !this.config.dispatch_areas.length)
                return false;
            let area = null;
            this.config.dispatch_areas.forEach((dispatch_area) => {
                if (search === dispatch_area.area) area = dispatch_area;
            });
            return area;
        },
        close() {
            if (this.step === 2) this.$emit("saved");
            else this.$emit("close");
        },
        closeFromModal: function (e) {
            e.stopPropagation();
            if (e && e.target && !e.target.classList.contains("modal-wrapper")) {
                e.stopPropagation();
            } else {
                this.close();
            }
        },
        copyToken() {
            let txtCopy = document.getElementById("noshow-gv-txt-copy-url");
            txtCopy.select();
            txtCopy.setSelectionRange(0, 99999); // For mobile devices
            try {
                if (!document.execCommand("copy")) throw "Can't copy";
                this.currentCopyTooltip = { content: "Le lien a été copié !" };
            } catch (err) {
                this.currentCopyTooltip = { content: "Le lien n'a pas pu être copié" };
            }
            window.getSelection().removeAllRanges();
        },
    },
    watch: {
        client: {
            deep: true,
            handler(newVal) {
                this.client = newVal;
            },
        },
        beneficiaryAddress: function (value) {
            this.beneficiary.beneficiary_address = value && value.street_address;
            this.beneficiary.beneficiary_postal_code = value && value.postal_code;
            this.beneficiary.beneficiary_city = value && value.locality;
            this.beneficiary.beneficiary_country = (value && value.country) ?? "France";
        },
    },
    components: {
        ShowErrors,
        VueTelInput,
        LoaderComponent,
        StripePaymentForm,
        PayPlugPaymentForm,
        PostalAddressAutocomplete,
    },
    created() {
        this.getPaymentIntentType();
    },
};
</script>

<style>
.vue-tel-input {
    border: 1px solid #f7f7f7 !important;
    padding: 4px;
}
.vue-tel-input input {
    max-width: 65%;
}
</style>
