<template>
    <div>
        <div class="row">
            <div class="col-6">
                <strong :style="{ color: `${bgColor} !important` }">{{ productTypeLabel }} N° {{ sale.numero }}</strong>
            </div>
            <div class="col-6 text-right">
                <!-- <strong :style="{ color: `${bgColor} !important` }" v-html="commandDateAndTime"></strong> -->
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <p>
                    <strong>Client :</strong> {{ sale.client.firstname }} {{ sale.client.lastname }}<br />
                    <strong>Détails du {{ productTypeLabel.toLowerCase() }} cadeau :</strong><br />
                    <span v-if="sale.menu_quantity > 1">{{ sale.menu_quantity }} x </span>{{ sale.gv_product.name }}<br />
                    <template v-if="sale.gv_sale_product_options.data.length > 0">
                        <small v-for="option in sale.gv_sale_product_options.data" class="ml-3 d-block" :key="option.id">
                            {{ option.quantity }} x {{ option.gv_products_option.name }}
                        </small>
                    </template>
                </p>
                <div class="d-flex justify-content-between">
                    <strong :style="{ color: `${bgColor} !important` }"> Montant : {{ $utils.formatPrice(totalAmount) }}€ </strong>
                </div>
                <div class="mt-1">
                    <small class="text-muted">
                        En effectuant mon paiement, je déclare accepter les
                        <a class="legal-link" :href="customCgv || `${base_url}/gift_vouchers/widget/public/${this.$api_key}/cgv`" target="_blank">
                            conditions générales de vente
                        </a>
                        et la
                        <a class="legal-link" :href="`${base_url}/gift_vouchers/widget/public/${this.$api_key}/policy`" target="_blank">
                            politique de confidentialité des données personnelles
                        </a>
                        et y adhère sans réserve.
                    </small>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from "../../plugins/moment.js";

export default {
    props: {
        sale: {
            type: Object,
            required: true,
        },
    },
    computed: {
        base_url() {
            return NOSHOW_URL;
        },
        customCgv() {
            return this.$store.getters["restaurant/cgv"];
        },
        bgColor() {
            return this.$store.getters["restaurant/bgColor"];
        },
        totalAmount() {
            return this.sale.amount + (this.sale.expedition_fees || 0);
        },
        productTypeLabel() {
            switch (this.sale.gv_product.type) {
                case "amount":
                    return "Chèque";
                case "menu":
                default:
                    return "Bon";
            }
        },
    },
};
</script>

<style scoped>
a.legal-link {
    font-size: 12px;
    color: #212529 !important;
}

a.legal-link:hover {
    color: inherit !important;
}
</style>
