<template>
    <div class="container pt-2">
        <div class="row">
            <div class="col-lg-6 col-md-8 col-12" style="max-width: 700px; margin: 0 auto">
                <PaymentGroupCommandRecap :sale="sale" class="border-light b-radius-20 p-4 mb-3" />
                <div class="border-light b-radius-20 p-4 mb-3">
                    <div class="row">
                        <div class="col-12">
                            <p class="mt-0">Sélectionnez votre email pour procéder au réglement</p>
                            <template v-for="groupPayment in sale.group_payments.data">
                                <div
                                    :key="groupPayment.id"
                                    v-if="!groupPayment.is_captain"
                                    class="payment-mail-adress p-3 mb-2 border-light d-flex justify-content-between"
                                    :class="{ paid: groupPayment.status != 'pending' || !canPay }"
                                    @click="selectEmail(groupPayment)">
                                    <span
                                        >{{ groupPayment.email }}
                                        {{ groupPayment.status != "pending" ? " - payé" : "" }}</span
                                    >
                                    <strong
                                        :style="
                                            groupPayment.status === 'pending' && canPay
                                                ? { color: `${bgColor} !important` }
                                                : ''
                                        "
                                        >{{ $utils.formatPrice(groupPayment.amount) }} €</strong
                                    >
                                </div>
                                <template
                                    v-else-if="
                                        groupPayment.is_captain &&
                                        groupPayment.status === 'pending' &&
                                        atLeastOneMissingPayment
                                    ">
                                    <div :key="groupPayment.id" class="payment-mail-adress p-3 mb-2 border-light paid">
                                        <div class="d-flex justify-content-between">
                                            <span
                                                >{{ groupPayment.email }}
                                                {{ groupPayment.status != "pending" ? " - payé" : "" }}</span
                                            >
                                            <strong>{{ $utils.formatPrice(groupPayment.amount) }} €</strong>
                                        </div>
                                        <small v-if="canPay"
                                            >Vous devez attendre tous les paiements avant de procéder au vôtre</small
                                        >
                                    </div>
                                </template>
                                <template v-else>
                                    <div
                                        :key="groupPayment.id"
                                        class="payment-mail-adress p-3 mb-2 border-light"
                                        :class="{ paid: groupPayment.status != 'pending' || !canPay }">
                                        <div class="d-flex justify-content-between" @click="selectEmail(groupPayment)">
                                            <span
                                                >{{ groupPayment.email }}
                                                {{ groupPayment.status != "pending" ? " - payé" : "" }}</span
                                            >
                                            <strong
                                                :style="
                                                    groupPayment.status === 'pending' && canPay
                                                        ? { color: `${bgColor} !important` }
                                                        : ''
                                                "
                                                >{{ $utils.formatPrice(groupPayment.amount) }} €</strong
                                            >
                                        </div>
                                    </div>
                                </template>
                            </template>
                            <p class="mb-0" v-if="sale.status === 'refund'">Cette commande a été remboursé.</p>
                            <p class="mb-0" v-else-if="canPay && sale.status === 'pending'">
                                Vous avez jusqu'à {{ canPayUntil.format("HH[h]mm") }} pour procéder au réglement.
                            </p>
                            <p class="mb-0" v-else-if="canPay && sale.status === 'captured'">
                                L'ensemble des paiements ont été effectués.
                            </p>
                            <p class="mb-0 text-danger" v-else>
                                Délai de paiement dépassé, votre commande à été annulée.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PaymentGroupCommandRecap from "./PaymentGroupCommandRecap.vue";
import moment from "../../plugins/moment";

export default {
    data() {
        return {
            moment,
        };
    },
    props: {
        sale: {
            type: Object,
            required: true,
        },
    },
    methods: {
        selectEmail(groupPayment) {
            if (groupPayment.status != "pending" || !this.canPay) return;
            window.history.pushState(
                { path: `${document.location.href}&email=${groupPayment.email}` },
                "",
                `${document.location.href}&email=${groupPayment.email}`
            );
            this.$emit("select");
        },
    },
    computed: {
        atLeastOneMissingPayment() {
            return this.sale.group_payments.data.some(
                (groupPayment) => !groupPayment.is_captain && groupPayment.status === "pending"
            );
        },
        canPayUntil() {
            return moment(this.sale.created_at).add(30, "minutes");
        },
        canPay() {
            return this.canPayUntil.isAfter(moment());
        },
        bgColor() {
            return this.$store.getters["restaurant/bgColor"];
        },
    },
    components: {
        PaymentGroupCommandRecap,
    },
};
</script>
