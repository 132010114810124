<template>
    <div class="loading" :style="loadingColor">
        <div class="spinner-grow text-blue-light" role="status">
            <span class="sr-only">{{ $t("loading") }}</span>
        </div>
    </div>
</template>

<script>
export default {
    computed: {
        restaurantBgColor() {
            return this.$store.getters["restaurant/bgColor"];
        },
        loadingColor() {
            return { color: this.restaurantBgColor };
        },
    },
};
</script>
