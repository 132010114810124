var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.hasErrors
    ? _c("small", {
        class: _vm.errClass,
        domProps: { innerHTML: _vm._s(_vm.joinErrors) },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }