export default {
    errors: {
        common: {
            unknown: "An error occured",
            empty_cart: "Your cart is empty",
            cant_be_pay: "This command can not be paid anymore",
            already_paid: "This command has already been paid",
            maintenance: "Widget is not available for now. Please try again later.",
            network: "Please check your network connection",
            address: {
                noHits: "This postal address has not been found.",
            },
        },
        products: {
            custom_amount: {
                between: "Please enter an amount between {min}€ and {max}€",
                not_available: "Sorry, custom amounts aren not available for this gift voucher",
            },
            amount: {
                invalid: "Sorry, this amount isn't available",
            },
        },
        expedition: {
            area_not_available: "Sorry, this dispatch area is not available",
        },
        group_payment: {
            sale_not_found: "Sorry, this command could not be found",
            email_not_found: "Sorry, this email address does not match any payment",
            captain_cant_pay: "You must wait for all other paiements before processing yours",
            already_paid: "This payment has already been made",
            too_late: "Payment delay outdated",
        },
    },
    confirm: {
        products: {
            delete: "Are you sure you want to delete this product from your cart ?",
        },
    },
    info: {
        paid: "Your payment has been made !",
    },
    loading: "Loading ...",
};
