var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-12" },
        [
          !_vm.paid
            ? [
                _vm.error
                  ? _c("span", { staticClass: "d-block text-danger" }, [
                      _vm._v(_vm._s(_vm.error)),
                    ])
                  : _vm.alreadyPaid
                  ? _c("span", { staticClass: "d-block text-danger" }, [
                      _vm._v(_vm._s(_vm.$t("errors.common.already_paid"))),
                    ])
                  : [
                      _vm.canPay
                        ? _c("p", [
                            _vm._v(
                              "Renseignez vos informations de carte bancaire"
                            ),
                          ])
                        : _c("p", { staticClass: "text-danger" }, [
                            _vm._v(_vm._s(_vm.$t("errors.common.cant_be_pay"))),
                          ]),
                      _vm._v(" "),
                      _vm.loadingPayment
                        ? _c("LoaderComponent", {
                            staticClass: "w-100 text-center",
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.canPay
                        ? [
                            _vm.paymentIntentType ===
                            _vm.CARD_PAYMENT_SYSTEM_STRIPE.value
                              ? _c("StripePaymentForm", {
                                  ref: "stripePaymentForm",
                                  staticClass: "col-12",
                                  attrs: {
                                    paymentIntent: _vm.paymentIntent,
                                    client: _vm.client,
                                    stripe_client_id: _vm.stripe_client_id,
                                  },
                                  on: {
                                    "set-loading": function ($event) {
                                      _vm.loadingPayment = $event
                                    },
                                    paid: function ($event) {
                                      _vm.paid = true
                                    },
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.paymentIntentType ===
                            _vm.CARD_PAYMENT_SYSTEM_PAYPLUG.value
                              ? _c("PayPlugPaymentForm", {
                                  ref: "payplugPaymentForm",
                                  staticClass: "col-12",
                                  attrs: {
                                    paymentIntent: _vm.paymentIntent,
                                    client: _vm.client,
                                  },
                                  on: {
                                    "set-loading": function ($event) {
                                      _vm.loadingPayment = $event
                                    },
                                    paid: function ($event) {
                                      _vm.paid = true
                                    },
                                  },
                                })
                              : _vm._e(),
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.errorCard
                        ? _c("span", { staticClass: "d-block text-danger" }, [
                            _vm._v(_vm._s(_vm.errorCard)),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", { staticClass: "w-100 text-right" }, [
                        _vm.canPay
                          ? _c(
                              "button",
                              {
                                staticClass: "mt-2 btn main-button",
                                style: {
                                  "background-color": `${_vm.bgColor} !important`,
                                },
                                attrs: { disabled: _vm.loadingPayment },
                                on: { click: _vm.processPayment },
                              },
                              [
                                _vm._v(
                                  "\n                            Payer\n                        "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]),
                    ],
              ]
            : _c("div", [_vm._v("Votre paiement a bien été pris en compte !")]),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }