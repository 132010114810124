<template>
    <modal @close="closeFromModal">
        <div slot="header" class="w-100">
            <button type="button" @click="close" class="close text-right" data-dismiss="modal" aria-label="Close">
                <span style="color: grey" aria-hidden="true">&times;</span>
            </button>
            <div
                v-if="product.img !== null"
                class="product-image-modal"
                :style="`background-image: url('${product.img}'); height: 360px;`"></div>
        </div>
        <div slot="body" class="p-3" style="position: relative">
            <h5 class="product-title noshow-widget-font-title" :style="`color: ${bgColor} !important`">
                {{ product.name }}
            </h5>
            <p class="product-description" v-html="product.description.replace(/\n/g, '<br/>')"></p>
            <div class="row" v-if="product.predefined_amounts.length > 0">
                <div class="col-5 pt-1">
                    <label class="noshow-widget-font-title">Choix du montant *</label>
                </div>
                <div class="col-7">
                    <select class="custom-select mb-0" v-model="amount">
                        <option :value="null">--</option>
                        <option v-for="amount in product.predefined_amounts" :key="amount" :value="amount">
                            {{ $utils.formatPrice(amount) + "€" }}
                        </option>
                        <option v-if="product.allow_custom_amounts" value="custom">Personnalisé</option>
                    </select>
                    <small class="text-danger" v-if="errorAmount">{{ errorAmount }}</small>
                </div>
            </div>
            <div
                v-if="(product.predefined_amounts.length === 0 && product.allow_custom_amounts) || amount === 'custom'"
                class="row mt-2">
                <div class="col-5 pt-1">
                    <label class="noshow-widget-font-title">Montant personnalisé (euros) *</label>
                </div>
                <div class="col-7">
                    <input
                        type="number"
                        v-model="customAmount"
                        :min="product.min_amount"
                        :max="product.max_amount"
                        class="form-control" />
                    <small class="text-danger" v-if="errorCustomAmount">{{ errorCustomAmount }}</small>
                </div>
            </div>
            <div class="product-infos-modal mt-4 pt-3">
                <div class="row mb-2">
                    <div class="col-5">Validité</div>
                    <div class="col-7">{{ product.validity }} jours</div>
                </div>
                <div class="row" v-if="product.terms_of_use">
                    <div class="col-5">Conditions d'utilisation</div>
                    <div class="col-7">
                        {{ product.terms_of_use }}
                    </div>
                </div>
            </div>
        </div>
        <div slot="footer" class="p-3">
            <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">
                <div></div>
                <div>
                    <div class="product-price d-inline-block mr-3 text-body">{{ priceFormatted }}€</div>
                    <button
                        :disabled="
                            !productActive ||
                            price === null ||
                            (errorCustomAmount !== null &&
                                (amount == 'custom' ||
                                    (product.predefined_amounts.length === 0 && product.allow_custom_amounts)))
                        "
                        @click="addToCart"
                        :style="`background: ${bgColor}`"
                        class="main-button product-modal-btn-add_product">
                        Ajouter
                    </button>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
export default {
    data() {
        return {
            amount: null,
            customAmount: null,
            errorCustomAmount: null,
            errorAmount: null,
        };
    },
    props: {
        product: {
            type: Object,
            required: true,
        },
    },
    computed: {
        bgColor() {
            return this.$store.getters["restaurant/bgColor"];
        },
        storeProduct() {
            return this.$store.getters["cart/product"];
        },
        productActive() {
            return this.product.active && this.storeProduct === null;
        },
        priceFormatted() {
            if (this.amount !== null && this.amount !== "custom" && !isNaN(this.amount))
                return this.$utils.formatPrice(this.amount);
            else if (
                this.amount === "custom" &&
                this.customAmount != null &&
                this.customAmount != "" &&
                !isNaN(this.customAmount) &&
                this.customAmount >= this.product.min_amount &&
                this.customAmount <= this.product.max_amount
            )
                return this.$utils.formatPrice(this.customAmount);
            return this.price === null ? "--" : this.$utils.formatPrice(this.price);
        },
        price() {
            if (this.amount !== null && this.amount !== "custom" && !isNaN(this.amount))
                return Number.parseFloat(this.amount);
            else if (
                (this.amount === "custom" ||
                    (this.product.predefined_amounts.length === 0 && this.product.allow_custom_amounts)) &&
                this.customAmount != null &&
                this.customAmount != "" &&
                !isNaN(this.customAmount) &&
                this.customAmount >= this.product.min_amount &&
                this.customAmount <= this.product.max_amount
            )
                return Number.parseFloat(this.customAmount);
            return null;
        },
    },
    methods: {
        addToCart() {
            this.$store.dispatch("cart/setProduct", {
                product: this.product,
                amount: this.price,
            });
            this.close();
        },
        close() {
            this.$emit("close");
        },
        closeFromModal: function (e) {
            e.stopPropagation();
            if (e && e.target && !e.target.classList.contains("modal-wrapper")) {
                e.stopPropagation();
            } else {
                this.close();
            }
        },
    },
    watch: {
        customAmount(newVal) {
            this.errorCustomAmount = null;
            if (newVal < this.product.min_amount || newVal > this.product.max_amount)
                this.errorCustomAmount = this.$t("errors.products.custom_amount.between", {
                    min: this.product.min_amount,
                    max: this.product.max_amount,
                });
        },
        amount(newVal) {
            this.errorAmount = null;
            if (newVal === null || (newVal === "custom" && this.product.allow_custom_amounts)) return;
            if (newVal === "custom" && !this.product.allow_custom_amounts)
                this.errorAmount = this.$t("errors.products.custom_amount.not_available");
            else if (!this.product.predefined_amounts.includes(this.amount))
                this.errorAmount = this.$t("errors.products.amount.invalid");
        },
    },
};
</script>
