export default {
    id: null,
    firstname: null,
    lastname: null,
    email: null,
    tel: null,
    tel_country: null,
    company: null,
    civility: null,
    address: null,
    postal_code: null,
    city: null,
    country: "France",
};
