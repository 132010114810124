<template>
    <div :class="layoutClass" style="box-sizing: border-box" class="notranslate" translate="no">
        <span v-html="custom_css"></span>
        <MaintenanceError v-if="inMaintenance" />
        <LicencesErrors v-else-if="hasLicenceError" />
        <component v-else-if="isInit" :is="`${layout}Layout`" />
    </div>
</template>

<script>
import MainLayout from "./layouts/MainLayout.vue";
import GroupPaymentLayout from "./layouts/GroupPaymentLayout.vue";
import PayFromBoLayout from "./layouts/PayFromBoLayout.vue";
import LicencesErrors from "./components/errors/LicencesErrors.vue";
import MaintenanceError from "./components/errors/MaintenanceError.vue";

export default {
    data() {
        return {
            custom_css: "",
            isInit: false,
        };
    },
    components: {
        MainLayout,
        GroupPaymentLayout,
        PayFromBoLayout,
        LicencesErrors,
        MaintenanceError,
    },
    computed: {
        layout() {
            let currentRoute = location.href.split(this.$api_key);
            if (currentRoute.length === 1) {
                if (currentRoute[0].includes("/group_payment") && this.$utils.getQueryParameter("sale")) return "GroupPayment";
                if (currentRoute[0].includes("/pay") && this.$utils.getQueryParameter("sale")) return "PayFromBo";
            } else if (currentRoute.length > 1) {
                currentRoute = currentRoute[1];
                if (currentRoute.startsWith("/page/new/group_payment") && this.$utils.getQueryParameter("sale")) return "GroupPayment";
                if (currentRoute.startsWith("/page/new/pay") && this.$utils.getQueryParameter("sale")) return "PayFromBo";
            }
            return "Main";
        },
        layoutClass() {
            switch (this.layout) {
                case "GroupPayment":
                    return "payment-group";
                case "PayFromBo":
                    return "pay-from-bo";
                default:
                    return "noshow-giftvouchers-widget";
            }
        },
        hasLicenceError() {
            return this.$store.getters["error/getHasLicenceError"];
        },
        inMaintenance() {
            return this.$store.getters["error/getInMaintenance"];
        },
        bgColor() {
            return this.$store.getters["restaurant/bgColor"];
        },
        widgetFontTitle() {
            return this.$store.getters["restaurant/widgetFontTitle"];
        },
    },
    created() {
        this.$store.dispatch("restaurant/fetchRestaurantData").then(() => {
            this.custom_css = `
                    <style>
                        .noshow-giftvouchers-widget a:hover,
                        .payment-group a:hover,
                        .pay-from-bo a:hover {
                            color: ${this.bgColor} !important;
                        }
                        .noshow-giftvouchers-widget .noshow-widget-font-title {
                            font-family: ${this.widgetFontTitle} !important;
                        }
                    </style>
                `;
            this.isInit = true;
        });
    },
};
</script>

<style>
@import url(https://fonts.googleapis.com/css?family=DM+Serif+Display|Lato:400,700|Montserrat+Alternates:400,600|Open+Sans:400,600|Oswald:400,600|Playfair+Display:400,600|Poppins:400,600|Raleway:400,600|Roboto:400,700&display=swap);
</style>
