<template>
    <div class="container pt-2">
        <div class="row">
            <div class="col-lg-6 col-md-8 col-12" style="max-width: 700px; margin: 0 auto">
                <PaymentGroupCommandRecap :sale="sale" class="border-light b-radius-20 p-4 mb-3" />
                <LoaderComponent class="w-100 text-center" v-if="loading" />
                <div v-else-if="error">
                    <div class="mb-3 back-to-list" @click="$emit('go-back')">
                        <i class="fas fa-angle-left mr-1"></i> Voir la liste des emails
                    </div>
                    <div class="alert alert-danger">{{ error }}</div>
                </div>
                <div v-else class="border-light b-radius-20 p-4 mb-3">
                    <div class="row">
                        <div class="col-12">
                            <div class="mb-3 back-to-list" @click="$emit('go-back')">
                                <i class="fas fa-angle-left mr-1"></i> Voir la liste des emails
                            </div>
                            <template v-if="!paid">
                                <div class="d-flex justify-content-between">
                                    <strong>{{ groupPayment.email }}</strong>
                                    <strong :style="{ color: `${bgColor} !important` }"
                                        >Votre part : {{ $utils.formatPrice(groupPayment.amount) }}€</strong
                                    >
                                </div>
                                <p v-if="canPay">Renseignez vos informations de carte bancaire</p>
                                <LoaderComponent class="w-100 text-center" v-if="loadingPayment" />
                                <template v-if="canPay">
                                    <StripePaymentForm
                                        v-if="paymentIntentType === CARD_PAYMENT_SYSTEM_STRIPE.value"
                                        ref="stripePaymentForm"
                                        :paymentIntent="paymentIntent"
                                        :client="client"
                                        :stripe_client_id="stripe_client_id"
                                        @set-loading="loadingPayment = $event"
                                        @paid="paid = true" />
                                    <PayPlugPaymentForm
                                        v-if="paymentIntentType === CARD_PAYMENT_SYSTEM_PAYPLUG.value"
                                        ref="payplugPaymentForm"
                                        :paymentIntent="paymentIntent"
                                        :client="client"
                                        @set-loading="loadingPayment = $event"
                                        @paid="paid = true" />
                                </template>
                                <span class="d-block text-danger" v-if="errorCard">{{ errorCard }}</span>
                                <p class="mb-0" v-if="canPay">
                                    Vous avez jusqu'à {{ canPayUntil.format("HH[h]mm") }} pour procéder au réglement.
                                </p>
                                <p class="mb-0 text-danger" v-else>
                                    Délai de paiement dépassé, votre commande à été annulée.
                                </p>
                                <div class="w-100 text-right">
                                    <button
                                        v-if="canPay"
                                        :disabled="loadingPayment"
                                        @click="processPayment"
                                        class="btn main-button"
                                        :style="{ 'background-color': `${bgColor} !important` }">
                                        Payer
                                    </button>
                                </div>
                                <div class="secure-payment text-muted mt-3">
                                    <a href="https://stripe.com/fr" target="_blank"
                                        ><small
                                            ><feather class="feather-18" type="lock"></feather> Paiement sécurisé</small
                                        ></a
                                    >
                                </div>
                            </template>
                            <div v-else>Votre paiement a bien été pris en compte !</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PaymentGroupCommandRecap from "./PaymentGroupCommandRecap.vue";
import moment from "../../plugins/moment";
import LoaderComponent from "../LoaderComponent.vue";
import axios from "axios";
import StripePaymentForm from "../PaymentForms/Stripe.vue";
import PayPlugPaymentForm from "../PaymentForms/PayPlug.vue";
import CardPaymentSystemEnum from "../../mixins/enums/CardPaymentSystemEnum.js";

export default {
    data() {
        return {
            loading: false,
            loadingPayment: false,
            error: null,
            errorCard: null,
            paymentIntent: null,
            groupPayment: null,
            nbPaymentsRemaining: null,
            paid: false,
            paymentIntentType: null,
        };
    },
    mixins: [CardPaymentSystemEnum],
    props: {
        sale: {
            type: Object,
            required: true,
        },
    },
    computed: {
        client() {
            return {
                email: this.groupPayment.email,
            };
        },
        bgColor() {
            return this.$store.getters["restaurant/bgColor"];
        },
        customerEmail() {
            this.refreshComputed;
            return this.$utils.getQueryParameter("email");
        },
        canPayUntil() {
            return moment(this.sale.created_at).add(30, "minutes");
        },
        canPay() {
            return this.canPayUntil.isAfter(moment());
        },
        stripe_client_id() {
            return this.$store.getters["restaurant/stripeClientId"];
        },
    },
    methods: {
        fetchGroupPayment() {
            this.loading = true;
            this.error = null;

            axios
                .get(`${API_URL}/group_payments/${this.sale.token}/${this.customerEmail}`)
                .then((response) => {
                    this.loading = false;
                    this.paymentIntentType = response.data.type;
                    this.paymentIntent = response.data.setup_intent;
                    this.groupPayment = response.data.group_payment;
                    this.nbPaymentsRemaining = response.data.nb_remaining_payments;
                    if (this.groupPayment.is_captain && this.nbPaymentsRemaining > 1)
                        this.error = this.$t("errors.group_payment.captain_cant_pay");
                    else if (this.groupPayment.status != "pending")
                        this.error = this.$t("errors.group_payment.already_paid");
                })
                .catch((error) => {
                    this.loading = false;
                    if (error.response && error.response.status === 404)
                        this.error = this.$t("errors.group_payment.email_not_found");
                    else if (error.response && error.response.data.message) this.error = error.response.data.message;
                    else if (error.message) this.error = error.message;
                    else this.error = this.$t("errors.common.unknown");
                });
        },
        processPayment() {
            this.errorCard = null;
            if (!this.paymentIntent || !this.canPay) {
                this.errorCard = this.$t("errors.group_payment.too_late");
                return;
            }
            this.$refs[`${this.paymentIntentType}PaymentForm`].processPayment();
        },
    },
    components: {
        PaymentGroupCommandRecap,
        LoaderComponent,
        StripePaymentForm,
        PayPlugPaymentForm,
    },
    created() {
        this.fetchGroupPayment();
    },
};
</script>
