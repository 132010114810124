var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.fatalError
      ? _c("small", { staticClass: "text-danger" }, [
          _vm._v(_vm._s(_vm.fatalError)),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }