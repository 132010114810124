var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "d-flex flex-column align-items-center justify-content-center",
      },
      [_c("h5", [_vm._v("Le widget est désactivé pour ce restaurant.")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }