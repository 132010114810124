export default {
    setProduct({ commit }, product) {
        commit("setProduct", product);
    },
    clearProduct({ commit }) {
        commit("setProduct", null);
    },
    setComment({ commit }, comment) {
        commit("setComment", comment);
    },
    clear({ commit }) {
        commit("setProduct", null);
        commit("setComment", null);
    },
};
