import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import CartStore from "./modules/cart/cart.js";
import RestaurantStore from "./modules/restaurant/restaurant.js";
import ClientStore from "./modules/client/client.js";
import ErrorStore from "./modules/error/error.js";

function isLocalStorageSupported() {
    try {
        const testKey = "__this_key_should_never_be_used__";

        localStorage.setItem(testKey, testKey);
        localStorage.removeItem(testKey);

        return true;
    } catch (e) {
        return false;
    }
}

Vue.use(Vuex);

export default function (token) {
    let plugins = [];

    if (isLocalStorageSupported()) {
        plugins.push(
            createPersistedState({
                key: `${THEME}-giftvouchers-${token.substr(0, 6)}`,
                paths: ["client", "cart", "restaurant.bgcolor"],
            })
        );
    }

    return new Vuex.Store({
        modules: {
            cart: CartStore,
            restaurant: RestaurantStore,
            client: ClientStore,
            error: ErrorStore,
        },
        plugins,
    });
}
