export default {
    setId(state, id) {
        state.id = id;
    },
    setFirstName(state, firstname) {
        state.firstname = firstname;
    },
    setLastName(state, lastname) {
        state.lastname = lastname;
    },
    setEmail(state, email) {
        state.email = email;
    },
    setTel(state, tel) {
        state.tel = tel;
    },
    setTelCountry(state, telCountry) {
        state.telCountry = telCountry;
    },
    setCompany(state, company) {
        state.company = company;
    },
    setCivility(state, civility) {
        state.civility = civility;
    },
    setAddress(state, address) {
        state.address = address;
    },
    setPostalCode(state, postal_code) {
        state.postal_code = postal_code;
    },
    setCity(state, city) {
        state.city = city;
    },
    setCountry(state, country) {
        state.country = country;
    },
};
