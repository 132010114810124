<template>
    <modal @close="closeFromModal">
        <div slot="header" class="w-100">
            <button type="button" @click="close" class="close text-right" aria-label="Close">
                <span style="color: grey" aria-hidden="true">&times;</span>
            </button>
        </div>
        <div slot="body" class="p-3" style="position: relative">
            <h5 class="product-title noshow-widget-font-title" :style="`color: ${bgColor} !important`">Information</h5>
            <p class="product-description">
                Si vous souhaitez commander plusieurs bons cadeaux, merci d'effectuer plusieurs commandes.<br />
                Pour modifier votre choix, merci de vider au préalable votre panier.
            </p>
        </div>
        <div slot="footer" class="p-3">
            <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">
                <div></div>
                <div>
                    <button @click="clearCart" type="button" class="btn">Vider mon panier</button>
                    <button @click="close" type="button" class="btn" style="color: #666666 !important">Fermer</button>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
export default {
    computed: {
        bgColor() {
            return this.$store.getters["restaurant/bgColor"];
        },
    },
    methods: {
        clearCart() {
            this.$store.dispatch("cart/clearProduct");
            this.close();
        },
        close() {
            this.$emit("close");
        },
        closeFromModal: function (e) {
            e.stopPropagation();
            if (e && e.target && !e.target.classList.contains("modal-wrapper")) {
                e.stopPropagation();
            } else {
                this.close();
            }
        },
    },
};
</script>
