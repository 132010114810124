var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("modal", { on: { close: _vm.closeFromModal } }, [
    _c(
      "div",
      { staticClass: "w-100", attrs: { slot: "header" }, slot: "header" },
      [
        _c(
          "button",
          {
            staticClass: "close text-right",
            attrs: {
              type: "button",
              "data-dismiss": "modal",
              "aria-label": "Close",
            },
            on: { click: _vm.close },
          },
          [
            _c(
              "span",
              {
                staticStyle: { color: "grey" },
                attrs: { "aria-hidden": "true" },
              },
              [_vm._v("×")]
            ),
          ]
        ),
        _vm._v(" "),
        _vm.product.img !== null
          ? _c("div", {
              staticClass: "product-image-modal",
              style: `background-image: url('${_vm.product.img}'); height: 360px;`,
            })
          : _vm._e(),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "p-3",
        staticStyle: { position: "relative" },
        attrs: { slot: "body" },
        slot: "body",
      },
      [
        _c(
          "h5",
          {
            staticClass: "product-title noshow-widget-font-title",
            style: `color: ${_vm.bgColor} !important`,
          },
          [_vm._v("\n            " + _vm._s(_vm.product.name) + "\n        ")]
        ),
        _vm._v(" "),
        _c("p", {
          staticClass: "product-description",
          domProps: {
            innerHTML: _vm._s(_vm.product.description.replace(/\n/g, "<br/>")),
          },
        }),
        _vm._v(" "),
        _vm.product.predefined_amounts.length > 0
          ? _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-5 pt-1" }, [
                _c("label", { staticClass: "noshow-widget-font-title" }, [
                  _vm._v("Choix du montant *"),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-7" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.amount,
                        expression: "amount",
                      },
                    ],
                    staticClass: "custom-select mb-0",
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.amount = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                    },
                  },
                  [
                    _c("option", { domProps: { value: null } }, [_vm._v("--")]),
                    _vm._v(" "),
                    _vm._l(_vm.product.predefined_amounts, function (amount) {
                      return _c(
                        "option",
                        { key: amount, domProps: { value: amount } },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.$utils.formatPrice(amount) + "€") +
                              "\n                    "
                          ),
                        ]
                      )
                    }),
                    _vm._v(" "),
                    _vm.product.allow_custom_amounts
                      ? _c("option", { attrs: { value: "custom" } }, [
                          _vm._v("Personnalisé"),
                        ])
                      : _vm._e(),
                  ],
                  2
                ),
                _vm._v(" "),
                _vm.errorAmount
                  ? _c("small", { staticClass: "text-danger" }, [
                      _vm._v(_vm._s(_vm.errorAmount)),
                    ])
                  : _vm._e(),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        (_vm.product.predefined_amounts.length === 0 &&
          _vm.product.allow_custom_amounts) ||
        _vm.amount === "custom"
          ? _c("div", { staticClass: "row mt-2" }, [
              _c("div", { staticClass: "col-5 pt-1" }, [
                _c("label", { staticClass: "noshow-widget-font-title" }, [
                  _vm._v("Montant personnalisé (euros) *"),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-7" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.customAmount,
                      expression: "customAmount",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: {
                    type: "number",
                    min: _vm.product.min_amount,
                    max: _vm.product.max_amount,
                  },
                  domProps: { value: _vm.customAmount },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.customAmount = $event.target.value
                    },
                  },
                }),
                _vm._v(" "),
                _vm.errorCustomAmount
                  ? _c("small", { staticClass: "text-danger" }, [
                      _vm._v(_vm._s(_vm.errorCustomAmount)),
                    ])
                  : _vm._e(),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "product-infos-modal mt-4 pt-3" }, [
          _c("div", { staticClass: "row mb-2" }, [
            _c("div", { staticClass: "col-5" }, [_vm._v("Validité")]),
            _vm._v(" "),
            _c("div", { staticClass: "col-7" }, [
              _vm._v(_vm._s(_vm.product.validity) + " jours"),
            ]),
          ]),
          _vm._v(" "),
          _vm.product.terms_of_use
            ? _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-5" }, [
                  _vm._v("Conditions d'utilisation"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-7" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.product.terms_of_use) +
                      "\n                "
                  ),
                ]),
              ])
            : _vm._e(),
        ]),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "p-3", attrs: { slot: "footer" }, slot: "footer" },
      [
        _c(
          "div",
          {
            staticClass:
              "d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center",
          },
          [
            _c("div"),
            _vm._v(" "),
            _c("div", [
              _c(
                "div",
                { staticClass: "product-price d-inline-block mr-3 text-body" },
                [_vm._v(_vm._s(_vm.priceFormatted) + "€")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "main-button product-modal-btn-add_product",
                  style: `background: ${_vm.bgColor}`,
                  attrs: {
                    disabled:
                      !_vm.productActive ||
                      _vm.price === null ||
                      (_vm.errorCustomAmount !== null &&
                        (_vm.amount == "custom" ||
                          (_vm.product.predefined_amounts.length === 0 &&
                            _vm.product.allow_custom_amounts))),
                  },
                  on: { click: _vm.addToCart },
                },
                [_vm._v("\n                    Ajouter\n                ")]
              ),
            ]),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }