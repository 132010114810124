export default {
    setClient({ commit }, client) {
        commit("setId", client.id);
        commit("setFirstName", client.firstname);
        commit("setLastName", client.lastname);
        commit("setEmail", client.email);
        commit("setTel", client.tel);
        commit("setTelCountry", client.telCountry);
        commit("setCompany", client.company);
        commit("setCivility", client.civility);
        commit("setAddress", client.address);
        commit("setPostalCode", client.postal_code);
        commit("setCity", client.city);
        commit("setCountry", client.country);
    },
    setFirstName({ commit }, firstname) {
        commit("setFirstName", firstname);
    },
    setLastName({ commit }, lastname) {
        commit("setLastName", lastname);
    },
    setEmail({ commit }, email) {
        commit("setEmail", email);
    },
    setTel({ commit }, tel) {
        commit("setTel", tel);
    },
    setTelCountry({ commit }, telCountry) {
        commit("setTelCountry", telCountry);
    },
    setCompany({ commit }, company) {
        commit("setCompany", company);
    },
    setCivility({ commit }, civility) {
        commit("setCivility", civility);
    },
    setAddress({ commit }, address) {
        commit("setAddress", address);
    },
    setPostalCode({ commit }, postal_code) {
        commit("setPostalCode", postal_code);
    },
    setCity({ commit }, city) {
        commit("setCity", city);
    },
    setCountry({ commit }, country) {
        commit("setCountry", country);
    },
};
