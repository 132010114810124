export default {
    setBgColor(state, bgcolor) {
        state.bgcolor = bgcolor;
    },
    setStripeClientId(state, stripe_client_id) {
        state.stripe_client_id = stripe_client_id;
    },
    setHasCardPaymentSystem(state, has_card_payment_system) {
        state.has_card_payment_system = has_card_payment_system;
    },
    setConfig(state, config) {
        state.config = config;
    },
    setWidgetFontTitle(state, widget_font_title) {
        state.widget_font_title = widget_font_title;
    },
    setDefaultTelCountry(state, default_tel_country) {
        state.default_tel_country = default_tel_country;
    },
    setTimezone(state, timezone) {
        state.timezone = timezone;
    },
};
