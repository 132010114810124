<template>
    <div>
        <div class="cart p-3">
            <h5 class="noshow-widget-font-title" :style="`color: ${bgColor}`">Panier</h5>
            <small>Information : si vous souhaitez commander plusieurs bons cadeaux, merci d'effectuer plusieurs commandes.</small>
            <div class="cart-body mb-3">
                <div class="cart-product pt-1 pb-1 div-cart-price">
                    <div class="row" v-if="product !== null && product.product.type === 'menu'">
                        <div class="col-3 pr-1">
                            <input v-model="product.quantity" class="form-control" type="number" :min="1" />
                        </div>
                        <div class="col-6 pl-1 pr-1">
                            <p>
                                <strong>{{ product.product.name }}</strong
                                ><br />
                                <small v-for="option in product.options" :key="option.option.id" class="ml-2 d-block">
                                    {{ option.quantity }} x {{ option.option.name }}
                                </small>
                            </p>
                        </div>
                        <div class="col-3 pl-1 text-right">
                            <p>
                                <strong class="cart-price">{{ price }}€</strong>
                                <button type="button" @click="deleteCart" class="btn btn-sm text-danger p-0 pl-1 float-right btn-delete-item">
                                    <i class="fas fa-times"></i>
                                </button>
                            </p>
                        </div>
                    </div>
                    <div class="row" v-else-if="product !== null && product.product.type === 'amount'">
                        <div class="col-9 pl-3">
                            <p>
                                <strong>{{ product.product.name }}</strong
                                ><br />
                            </p>
                        </div>
                        <div class="col-3 pl-1 text-right">
                            <p>
                                <strong class="cart-price">{{ price }}€</strong>
                                <button type="button" @click="deleteCart" class="btn btn-sm text-danger p-0 pl-1 float-right btn-delete-item">
                                    <i class="fas fa-times"></i>
                                </button>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <textarea v-model="comment" class="form-control mb-2" rows="4" placeholder="Note pour l'établissement"></textarea>
            <button
                :disabled="price == '--' || !hasCardPaymentSystem"
                @click="$emit('show-modal')"
                :style="`background-color: ${bgColor}; width: 100%;`"
                class="main-button">
                <div class="row">
                    <div class="col-6 text-left">Paiement</div>
                    <div class="col-6 text-right">{{ price }}€</div>
                </div>
            </button>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            showModal: false,
            errors: {},
        };
    },
    computed: {
        hasCardPaymentSystem() {
            return this.$store.getters["restaurant/hasCardPaymentSystem"];
        },
        price() {
            if (this.product != null && this.product.product.type === "menu") {
                if (!this.product.quantity || this.product.quantity < 1) return "--";
                return this.$utils.formatPrice(this.menuPrice(this.product));
            }
            if (this.product != null && this.product.product.type === "amount") return this.amountPrice(this.product);
            return "--";
        },
        bgColor() {
            return this.$store.getters["restaurant/bgColor"];
        },
        comment: {
            get() {
                return this.$store.getters["cart/comment"];
            },
            set(newVal) {
                this.$store.dispatch("cart/setComment", newVal);
            },
        },
        product: {
            get() {
                return this.$store.getters["cart/product"];
            },
            set(newVal) {
                this.$store.dispatch("cart/setProduct", newVal);
            },
        },
    },
    methods: {
        menuPrice(menu) {
            let amount = menu.product.price * menu.quantity;
            this.product.options.forEach((option) => {
                if (!option.option.include) amount += option.option.price * option.quantity;
            });
            return amount;
        },
        amountPrice(product) {
            if (product.product.predefined_amounts.length === 1 && !product.product.allow_custom_amounts)
                return this.$utils.formatPrice(product.product.predefined_amounts[0]);
            if (Object.keys(product).includes("amount")) return this.$utils.formatPrice(product.amount);
            return "invalid";
        },
        deleteCart() {
            if (!confirm(this.$t("confirm.products.delete"))) return;
            this.$store.dispatch("cart/clearProduct");
        },
    },
    watch: {
        // Add deep handler to launch computed
        product: {
            deep: true,
            handler(newVal) {
                this.product = newVal;
            },
        },
    },
};
</script>
