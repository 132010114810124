<template>
    <div>
        <div class="row">
            <div class="col-12">
                <strong :style="{ color: `${bgColor} !important` }">{{ productTypeLabel }} N° {{ sale.numero }}</strong>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <p>
                    <strong>Client :</strong> {{ sale.client.firstname }} {{ sale.client.lastname }}<br />
                    <strong>Détails du {{ productTypeLabel.toLowerCase() }} cadeau :</strong><br />
                    <span v-if="sale.menu_quantity > 1">{{ sale.menu_quantity }} x </span>{{ sale.gv_product.name
                    }}<br />
                    <template v-if="sale.gv_sale_product_options.data.length > 0">
                        <small
                            v-for="option in sale.gv_sale_product_options.data"
                            class="ml-3 d-block"
                            :key="option.id">
                            {{ option.quantity }} x {{ option.gv_products_option.name }}
                        </small>
                    </template>
                </p>
                <div class="d-flex justify-content-between">
                    <strong :style="{ color: `${bgColor} !important` }"
                        >Montant : {{ $utils.formatPrice(totalAmount) }}€</strong
                    >
                    <strong :style="{ color: `${bgColor} !important` }"
                        >Reste à régler : {{ $utils.formatPrice(remainingTotalAmount) }}€</strong
                    >
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from "../../plugins/moment.js";

export default {
    props: {
        sale: {
            type: Object,
            required: true,
        },
    },
    computed: {
        bgColor() {
            return this.$store.getters["restaurant/bgColor"];
        },
        productTypeLabel() {
            switch (this.sale.gv_product.type) {
                case "amount":
                    return "Chèque";
                case "menu":
                default:
                    return "Bon";
            }
        },
        totalAmount() {
            return this.sale.amount + (this.sale.expedition_fees || 0);
        },
        remainingTotalAmount() {
            let total_amount = this.totalAmount;
            this.sale.group_payments.data.forEach((groupPayment) => {
                if (groupPayment.paid_at != null) total_amount -= groupPayment.amount;
            });
            return total_amount;
        },
    },
};
</script>
