var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "notranslate",
      class: _vm.layoutClass,
      staticStyle: { "box-sizing": "border-box" },
      attrs: { translate: "no" },
    },
    [
      _c("span", { domProps: { innerHTML: _vm._s(_vm.custom_css) } }),
      _vm._v(" "),
      _vm.inMaintenance
        ? _c("MaintenanceError")
        : _vm.hasLicenceError
        ? _c("LicencesErrors")
        : _vm.isInit
        ? _c(`${_vm.layout}Layout`, { tag: "component" })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }