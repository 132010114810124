var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("modal", { on: { close: _vm.closeFromModal } }, [
    _c(
      "div",
      { staticClass: "w-100", attrs: { slot: "header" }, slot: "header" },
      [
        _c(
          "button",
          {
            staticClass: "close text-right",
            attrs: {
              type: "button",
              "data-dismiss": "modal",
              "aria-label": "Close",
            },
            on: { click: _vm.close },
          },
          [
            _c(
              "span",
              {
                staticStyle: { color: "grey" },
                attrs: { "aria-hidden": "true" },
              },
              [_vm._v("×")]
            ),
          ]
        ),
        _vm._v(" "),
        _vm.product.img !== null
          ? _c("div", {
              staticClass: "product-image-modal",
              style: `background-image: url('${_vm.product.img}'); height: 360px;`,
            })
          : _vm._e(),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "p-3",
        staticStyle: { position: "relative" },
        attrs: { slot: "body" },
        slot: "body",
      },
      [
        _c(
          "h5",
          {
            staticClass: "product-title noshow-widget-font-title",
            style: `color: ${_vm.bgColor} !important`,
          },
          [_vm._v("\n            " + _vm._s(_vm.product.name) + "\n        ")]
        ),
        _vm._v(" "),
        _c("p", {
          staticClass: "product-description",
          domProps: {
            innerHTML: _vm._s(_vm.product.description.replace(/\n/g, "<br/>")),
          },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "product-infos-modal mt-4 pt-3" }, [
          _c("div", { staticClass: "row mb-2" }, [
            _c("div", { staticClass: "col-5" }, [_vm._v("Validité")]),
            _vm._v(" "),
            _c("div", { staticClass: "col-7" }, [
              _vm._v(_vm._s(_vm.product.validity) + " jours"),
            ]),
          ]),
          _vm._v(" "),
          _vm.product.terms_of_use
            ? _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-5" }, [
                  _vm._v("Conditions d'utilisation"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-7" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.product.terms_of_use) +
                      "\n                "
                  ),
                ]),
              ])
            : _vm._e(),
        ]),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "p-3", attrs: { slot: "footer" }, slot: "footer" },
      [
        _c(
          "div",
          {
            staticClass:
              "d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center",
          },
          [
            _c("div"),
            _vm._v(" "),
            _c("div", [
              _c(
                "div",
                { staticClass: "product-price d-inline-block mr-3 text-body" },
                [_vm._v(_vm._s(_vm.$utils.getAmountPrice(_vm.product)) + "€")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "main-button product-modal-btn-add_product",
                  style: `background: ${_vm.bgColor}`,
                  attrs: { disabled: !_vm.productActive },
                  on: { click: _vm.addToCart },
                },
                [_vm._v("\n                    Ajouter\n                ")]
              ),
            ]),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }