var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("modal", { on: { close: _vm.closeFromModal } }, [
    _c(
      "div",
      { staticClass: "w-100", attrs: { slot: "header" }, slot: "header" },
      [
        _c(
          "button",
          {
            staticClass: "close text-right",
            attrs: { type: "button", "aria-label": "Close" },
            on: { click: _vm.close },
          },
          [
            _c(
              "span",
              {
                staticStyle: { color: "grey" },
                attrs: { "aria-hidden": "true" },
              },
              [_vm._v("×")]
            ),
          ]
        ),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "p-3",
        staticStyle: { position: "relative" },
        attrs: { slot: "body" },
        slot: "body",
      },
      [
        _c(
          "h5",
          {
            staticClass: "product-title noshow-widget-font-title",
            style: `color: ${_vm.bgColor} !important`,
          },
          [_vm._v("Information")]
        ),
        _vm._v(" "),
        _c("p", { staticClass: "product-description" }, [
          _vm._v(
            "\n            Si vous souhaitez commander plusieurs bons cadeaux, merci d'effectuer plusieurs commandes."
          ),
          _c("br"),
          _vm._v(
            "\n            Pour modifier votre choix, merci de vider au préalable votre panier.\n        "
          ),
        ]),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "p-3", attrs: { slot: "footer" }, slot: "footer" },
      [
        _c(
          "div",
          {
            staticClass:
              "d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center",
          },
          [
            _c("div"),
            _vm._v(" "),
            _c("div", [
              _c(
                "button",
                {
                  staticClass: "btn",
                  attrs: { type: "button" },
                  on: { click: _vm.clearCart },
                },
                [_vm._v("Vider mon panier")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn",
                  staticStyle: { color: "#666666 !important" },
                  attrs: { type: "button" },
                  on: { click: _vm.close },
                },
                [_vm._v("Fermer")]
              ),
            ]),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }