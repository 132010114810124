class Utils {
    formatPrice(price) {
        if (price === null || price === "--") return "--";
        return Number.parseFloat(price).toFixed(2).toString().replace(".", ",");
    }

    getAmountPrice(product) {
        if (product.predefined_amounts.length === 1 && !product.allow_custom_amounts) return this.formatPrice(product.predefined_amounts[0]);
        let min = product.predefined_amounts.length > 0 ? Math.min(...product.predefined_amounts) : null;
        let max = product.predefined_amounts.length > 0 ? Math.max(...product.predefined_amounts) : null;
        if (product.allow_custom_amounts) {
            if (min === null || product.min_amount < min) min = product.min_amount;
            if (max === null || product.max_amount < max) max = product.max_amount;
        }
        return this.formatPrice(min) + "€ - " + this.formatPrice(max);
    }

    getQueryParameter(param) {
        let url = location.href.split("?");
        if (url.length < 2) return null;
        let params = url[1].split("&");
        for (let i = 0; i < params.length; i++) {
            let keyValue = params[i].split("=");
            if (keyValue[0] === param) return keyValue.length > 1 ? keyValue[1] : null;
        }
        return null;
    }

    removeQueryParameter(param) {
        let url = location.href.split("?");
        let newUrl = url[0] + "?";
        if (url.length < 2) return location.href;
        let params = url[1].split("&");
        for (let i = 0; i < params.length; i++) {
            let keyValue = params[i].split("=");
            if (keyValue[0] !== param) {
                newUrl += "&" + keyValue[0];
                if (keyValue[1]) newUrl += "=" + keyValue[1];
            }
        }
        return newUrl;
    }

    getErrorMsgFromErrorResponse(error, defaultMessage = undefined) {
        if (error && error.response && error.response.data && error.response.data.message) {
            return error.response.data.message;
        }

        if (error && error.message) {
            return error.message.toLowerCase() === "network error" ? this.$t("errors.common.network") : error.message;
        }

        if (typeof error === "string" && error !== "") {
            return error;
        }

        return defaultMessage || this.$t("errors.common.unknown");
    }

    generateRandomString() {
        return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
            var r = (Math.random() * 16) | 0,
                v = c == "x" ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        });
    }
}

export default {
    install(Vue) {
        Vue.prototype.$utils = new Utils();
    },
};
