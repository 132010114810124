import Vue from "vue";
import VueI18n from "vue-i18n";
import fr from "../lang/fr.js";
import en from "../lang/en.js";

Vue.use(VueI18n);
const i18n = new VueI18n({
    fallbackLocale: "en",
    locale: window.navigator.language.slice(0, 2) !== "fr" ? "en" : "fr",
    messages: {
        fr,
        en,
    },
    silentTranslationWarn: false,
});

export default i18n;
