var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-6" }, [
        _c("strong", { style: { color: `${_vm.bgColor} !important` } }, [
          _vm._v(
            _vm._s(_vm.productTypeLabel) + " N° " + _vm._s(_vm.sale.numero)
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-6 text-right" }),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c(
          "p",
          [
            _c("strong", [_vm._v("Client :")]),
            _vm._v(
              " " +
                _vm._s(_vm.sale.client.firstname) +
                " " +
                _vm._s(_vm.sale.client.lastname)
            ),
            _c("br"),
            _vm._v(" "),
            _c("strong", [
              _vm._v(
                "Détails du " +
                  _vm._s(_vm.productTypeLabel.toLowerCase()) +
                  " cadeau :"
              ),
            ]),
            _c("br"),
            _vm._v(" "),
            _vm.sale.menu_quantity > 1
              ? _c("span", [_vm._v(_vm._s(_vm.sale.menu_quantity) + " x ")])
              : _vm._e(),
            _vm._v(_vm._s(_vm.sale.gv_product.name)),
            _c("br"),
            _vm._v(" "),
            _vm.sale.gv_sale_product_options.data.length > 0
              ? _vm._l(
                  _vm.sale.gv_sale_product_options.data,
                  function (option) {
                    return _c(
                      "small",
                      { key: option.id, staticClass: "ml-3 d-block" },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(option.quantity) +
                            " x " +
                            _vm._s(option.gv_products_option.name) +
                            "\n                    "
                        ),
                      ]
                    )
                  }
                )
              : _vm._e(),
          ],
          2
        ),
        _vm._v(" "),
        _c("div", { staticClass: "d-flex justify-content-between" }, [
          _c("strong", { style: { color: `${_vm.bgColor} !important` } }, [
            _vm._v(
              " Montant : " +
                _vm._s(_vm.$utils.formatPrice(_vm.totalAmount)) +
                "€ "
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "mt-1" }, [
          _c("small", { staticClass: "text-muted" }, [
            _vm._v(
              "\n                    En effectuant mon paiement, je déclare accepter les\n                    "
            ),
            _c(
              "a",
              {
                staticClass: "legal-link",
                attrs: {
                  href:
                    _vm.customCgv ||
                    `${_vm.base_url}/gift_vouchers/widget/public/${this.$api_key}/cgv`,
                  target: "_blank",
                },
              },
              [
                _vm._v(
                  "\n                        conditions générales de vente\n                    "
                ),
              ]
            ),
            _vm._v("\n                    et la\n                    "),
            _c(
              "a",
              {
                staticClass: "legal-link",
                attrs: {
                  href: `${_vm.base_url}/gift_vouchers/widget/public/${this.$api_key}/policy`,
                  target: "_blank",
                },
              },
              [
                _vm._v(
                  "\n                        politique de confidentialité des données personnelles\n                    "
                ),
              ]
            ),
            _vm._v(
              "\n                    et y adhère sans réserve.\n                "
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }