var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("strong", { style: { color: `${_vm.bgColor} !important` } }, [
          _vm._v(
            _vm._s(_vm.productTypeLabel) + " N° " + _vm._s(_vm.sale.numero)
          ),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c(
          "p",
          [
            _c("strong", [_vm._v("Client :")]),
            _vm._v(
              " " +
                _vm._s(_vm.sale.client.firstname) +
                " " +
                _vm._s(_vm.sale.client.lastname)
            ),
            _c("br"),
            _vm._v(" "),
            _c("strong", [
              _vm._v(
                "Détails du " +
                  _vm._s(_vm.productTypeLabel.toLowerCase()) +
                  " cadeau :"
              ),
            ]),
            _c("br"),
            _vm._v(" "),
            _vm.sale.menu_quantity > 1
              ? _c("span", [_vm._v(_vm._s(_vm.sale.menu_quantity) + " x ")])
              : _vm._e(),
            _vm._v(_vm._s(_vm.sale.gv_product.name)),
            _c("br"),
            _vm._v(" "),
            _vm.sale.gv_sale_product_options.data.length > 0
              ? _vm._l(
                  _vm.sale.gv_sale_product_options.data,
                  function (option) {
                    return _c(
                      "small",
                      { key: option.id, staticClass: "ml-3 d-block" },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(option.quantity) +
                            " x " +
                            _vm._s(option.gv_products_option.name) +
                            "\n                    "
                        ),
                      ]
                    )
                  }
                )
              : _vm._e(),
          ],
          2
        ),
        _vm._v(" "),
        _c("div", { staticClass: "d-flex justify-content-between" }, [
          _c("strong", { style: { color: `${_vm.bgColor} !important` } }, [
            _vm._v(
              "Montant : " +
                _vm._s(_vm.$utils.formatPrice(_vm.totalAmount)) +
                "€"
            ),
          ]),
          _vm._v(" "),
          _c("strong", { style: { color: `${_vm.bgColor} !important` } }, [
            _vm._v(
              "Reste à régler : " +
                _vm._s(_vm.$utils.formatPrice(_vm.remainingTotalAmount)) +
                "€"
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }