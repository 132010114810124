var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("modal", { on: { close: _vm.closeFromModal } }, [
    _c(
      "div",
      { staticClass: "w-100", attrs: { slot: "header" }, slot: "header" },
      [
        _c(
          "button",
          {
            staticClass: "close text-right",
            attrs: {
              type: "button",
              "data-dismiss": "modal",
              "aria-label": "Close",
            },
            on: { click: _vm.close },
          },
          [
            _c(
              "span",
              {
                staticStyle: { color: "grey" },
                attrs: { "aria-hidden": "true" },
              },
              [_vm._v("×")]
            ),
          ]
        ),
        _vm._v(" "),
        _vm.product.img !== null
          ? _c("div", {
              staticClass: "product-image-modal",
              style: `background-image: url('${_vm.product.img}'); height: 360px;`,
            })
          : _vm._e(),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "p-3",
        staticStyle: { position: "relative" },
        attrs: { slot: "body" },
        slot: "body",
      },
      [
        _c(
          "h5",
          {
            staticClass: "product-title noshow-widget-font-title",
            style: `color: ${_vm.bgColor} !important`,
          },
          [_vm._v("\n            " + _vm._s(_vm.product.name) + "\n        ")]
        ),
        _vm._v(" "),
        _c("p", {
          staticClass: "product-description",
          domProps: {
            innerHTML: _vm._s(_vm.product.description.replace(/\n/g, "<br/>")),
          },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "mt-3 pt-3 border-top" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-sm-5" }, [
              _c("h6", { staticClass: "noshow-widget-font-title" }, [
                _vm._v("Quantité souhaitée"),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-sm-7" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c(
                    "button",
                    {
                      staticClass: "main-button second-btn",
                      on: {
                        click: function ($event) {
                          _vm.quantity--
                        },
                      },
                    },
                    [_c("i", { staticClass: "fas fa-minus" })]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.quantity,
                        expression: "quantity",
                      },
                    ],
                    staticClass: "d-inline-block form-control pt-1 pl-1 pr-1",
                    staticStyle: { width: "40px", "text-align": "center" },
                    attrs: { type: "text" },
                    domProps: { value: _vm.quantity },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.quantity = $event.target.value
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "main-button second-btn",
                      on: {
                        click: function ($event) {
                          _vm.quantity++
                        },
                      },
                    },
                    [_c("i", { staticClass: "fas fa-plus" })]
                  ),
                ]),
              ]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _vm.product.gv_products_options.length > 0 && _vm.quantityData > 0
          ? _c(
              "div",
              { staticClass: "modal-payment-body mt-3 pt-3 border-top" },
              [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-5" }, [
                    _c("h6", { staticClass: "noshow-widget-font-title" }, [
                      _vm._v("Choix des options"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-7" }, [
                    _c(
                      "div",
                      { staticClass: "row" },
                      _vm._l(
                        _vm.product.gv_products_options,
                        function (option, index) {
                          return _c(
                            "div",
                            { key: option.id, staticClass: "col-12" },
                            [
                              _c("label", [
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.optionsQuantity[index],
                                        expression: "optionsQuantity[index]",
                                      },
                                    ],
                                    staticClass:
                                      "custom-select d-inline-block mr-2 product_option_quantity",
                                    staticStyle: { width: "60px" },
                                    on: {
                                      change: function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.$set(
                                          _vm.optionsQuantity,
                                          index,
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      },
                                    },
                                  },
                                  _vm._l(_vm.nbPers(), function (i) {
                                    return _c(
                                      "option",
                                      {
                                        key: `${option.id}-${i}`,
                                        domProps: { value: i },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                        " +
                                            _vm._s(i) +
                                            "\n                                    "
                                        ),
                                      ]
                                    )
                                  }),
                                  0
                                ),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  { staticStyle: { "font-size": "13px" } },
                                  [
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(option.name) +
                                        " -\n                                    " +
                                        _vm._s(
                                          option.include
                                            ? "Inclus"
                                            : _vm.$utils.formatPrice(
                                                option.price
                                              ) + "€"
                                        ) +
                                        "\n                                "
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          )
                        }
                      ),
                      0
                    ),
                  ]),
                ]),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "product-infos-modal mt-4 pt-3" }, [
          _c("div", { staticClass: "row mb-2" }, [
            _c("div", { staticClass: "col-5" }, [_vm._v("Validité")]),
            _vm._v(" "),
            _c("div", { staticClass: "col-7" }, [
              _vm._v(_vm._s(_vm.product.validity) + " jours"),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row mb-2" }, [
            _c("div", { staticClass: "col-5" }, [
              _vm._v("Nombre de personne(s)"),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-7" }, [
              _vm._v(
                "\n                    " +
                  _vm._s(_vm.product.nb_pers) +
                  "\n                "
              ),
            ]),
          ]),
          _vm._v(" "),
          _vm.product.terms_of_use
            ? _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-5" }, [
                  _vm._v("Conditions d'utilisation"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-7" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.product.terms_of_use) +
                      "\n                "
                  ),
                ]),
              ])
            : _vm._e(),
        ]),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "p-3 footer-modal",
        attrs: { slot: "footer" },
        slot: "footer",
      },
      [
        _c(
          "div",
          {
            staticClass:
              "d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center",
          },
          [
            _c("div"),
            _vm._v(" "),
            _c("div", [
              _c("div", { staticClass: "product-price d-inline-block mr-3" }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(
                      _vm.quantityData > 0
                        ? _vm.$utils.formatPrice(_vm.productPrice)
                        : "--"
                    ) +
                    "€\n                "
                ),
              ]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "main-button product-modal-btn-add_product",
                  style: `background: ${_vm.bgColor}`,
                  attrs: {
                    disabled: !_vm.productActive || _vm.quantityData < 1,
                  },
                  on: { click: _vm.addToCart },
                },
                [_vm._v("\n                    Ajouter\n                ")]
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _vm.errorQuantity
          ? _c("small", { staticClass: "text-danger" }, [
              _vm._v(_vm._s(_vm.errorQuantity)),
            ])
          : _vm._e(),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }